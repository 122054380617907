<template lang="pug">
  #card-message(v-if="show && message")
    .cuadro.sombra.mb-2.mr-2
      div.d-inline-flex
        .icon.material-icons(style="color:var(--status-in-transit)") info_icon
        div
          strong {{message.subject}}
          div {{message.text}}
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: true
    }
  }
}
</script>
<style lang="scss" scoped>
.cuadro {
    background-color: white !important;
    padding: 20px;
    display: flex;
    width: 100%;
    // height: 130px;
    justify-content: space-between;
    border-radius: 5px;
}
.icon {
  width: 32px !important;
}
</style>
