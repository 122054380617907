import axios from '../../plugins/axios'
import fileDownload from 'js-file-download'
import moment from 'moment-timezone'

export const listWithdrawals = ({ commit }, { params } = {}) => {
  return axios.get('/withdrawal', { params })
    .then((response) => {
      commit('SET_WITHDRAWALS', response.data.rows)
      return response
    })
    .catch(err => err.response)
}

export const createWithdrawal = (_, { data }) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }
  return axios.post('/withdrawal', data, config)
    .then(response => {
      return response
    })
    .catch(err => err.response)
}

export const updateWithdrawal = (_, { withdrawalId, data }) => {
  return axios.put(`/withdrawal/${withdrawalId}`, data)
    .then(res => {
      return res.data
    })
    .catch(err => err.response)
}

export const exportPackagesWithdrawals = (_, { params } = {}) => {
  return axios.get('/export-withdrawal', { params, responseType: 'blob' })
    .then(response => {
      const format = (date) => {
        return moment(date).format('DD-MM-YYYY')
      }
      const fileName = params.startDate
        ? format(params.startDate) + '_al_' + format(params.endDate)
        : format(params.retirementDate)

      fileDownload(response.data, fileName + '_Envios.xlsx')
      return response
    })
    .catch(err => err.response)
}

export const exportPackagesWithdrawalsNoDrivIn = (_, { params } = {}) => {
  const { retirementDate, startDate, endDate } = params
  return axios.get('/export-withdrawal-nodrivin', { params, responseType: 'blob' })
    .then(response => {
      const now = moment.tz('America/Santiago')
      const formatedDate = moment(retirementDate).format('DD-MM-YYYY')
      const format = (date) => {
        return moment(date).format('DD-MM-YYYY')
      }

      const filename = startDate
        ? `${format(startDate)}_al_${format(endDate)}_Retiros_.xlsx`
        : now.format(`[${formatedDate}][_Retiros_]HH:mm[.xlsx]`)
      fileDownload(response.data, filename)
      return response
    })
    .catch(err => err.response)
}

export const checkWithdrawalHour = (_, data) => {
  return axios.post('/check-withdrawal-hour', data)
    .then(response => {
      return response.data
    })
    .catch(err => err.response)
}

export const getSameDateWithdrawals = (_, { params } = {}) => {
  return axios.get('/same-date-withdrawal', { params })
    .then(response => {
      return response.data
    })
    .catch(err => err.response)
}
