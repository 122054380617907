<template lang="pug">
  b-container(fluid="lg")
    list-package(:selectedPackage="selectedPackage")
</template>

<script>
import ListPackage from '../components/package/ListPackage.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ListPackage
  },
  async created () {
    const packageCode = location.pathname.split('/')[2]
    await this.updateReceptionPackage({ code: packageCode, data: { receptionStatus: 'received' } })
    await this.listPackage({ code: packageCode })
    // this.$bvModal.show('withdrawal-detail')
  },
  async mounted () {
    this.$bvModal.show('withdrawal-detail')
  },
  methods: {
    ...mapActions(['listPackage', 'updateReceptionPackage'])
  },
  computed: {
    ...mapGetters(['selectedPackage'])
  }
}
</script>
