import axios from '../../plugins/axios'

export const listRegion = ({ commit }, { params } = {}) => {
  return axios.get('/region', { params })
    .then((response) => {
      commit('SET_REGIONS', response.data)
      return response
    })
    .catch(err => err.response)
}

export const createRegion = (_, { data }) => {
  return axios.post('/region', data)
    .then(response => response)
    .catch(err => err.response)
}

export const getRegion = ({ commit }, { regionId } = {}) => {
  return axios.get(`region/${regionId}`)
    .then((response) => {
      commit('SET_REGION', response.data)
      return response
    })
    .catch(err => err.response)
}

export const deleteRegion = (_, { id }) => {
  return axios.delete(`/region/${id}`)
    .then(response => response)
    .catch(err => err.response)
}

export const updateRegion = (_, { data, id }) => {
  return axios.put(`/region/${id}`, data)
    .then(response => response)
    .catch(err => err.response)
}
