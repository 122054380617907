<template lang="pug">
  b-container(fluid="lg")
    .tabla.sombra
      list-regions
</template>

<script>
import ListRegions from '../components/region/ListRegions'

export default {
  components: {
    ListRegions
  }
}
</script>
