<template lang="pug">
#RecoverPassword
  center
    .check-circle
      .material-icons.done-icon done
    h5.text-center {{ !result ? recoverText : sendedText }}
    p.text(v-html="!result ? recoverDescription : sendedDescription")
    b-form#RecoverForm(@submit="onSubmit", v-if="!result")
      b-input-group.mb-2(label-for="input-email")
        b-input-group-prepend(is-text)
          .material-icons.icon email
        b-form-input(
          id="input-email"
          type="email"
          placeholder="Ingresa tu correo"
          v-model="email"
          required)
      .text-center(v-if="!result")
        b-button(type="submit" style="margin-top:20px; width: 240px;", variant="status-approved") Enviar
      .text-center(v-else)
        b-button(type="submit" style="margin-top:20px; width: 240px;" variant="status-approved", :to="{ name: 'Login' }") Aceptar
</template>
<script>
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      email: '',
      result: false,
      recoverText: 'Recuperar contraseña',
      sendedText: 'Correo Enviado',
      recoverDescription: 'Ingresa el correo electrónico asociado a tu cuenta, te enviaremos las instrucciones para restablecer la contraseña.',
      sendedDescription: 'Hemos enviado las instrucciones para restablecer tu contraseña a la dirección de correo electrónico. <br><br> Si no recibes las instrucciones, comprueba en la bandeja spam, o intenta reenviar la solicitud.'
    }
  },
  methods: {
    ...mapActions(['sendRecoverPasswordEmail']),
    async onSubmit (event) {
      event.preventDefault()
      const response = await this.sendRecoverPasswordEmail({ email: this.email })
      if (response) {
        this.result = true
      } else {
        this.makeDangerToast('Error en el correo enviado')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../style/colors.scss';
#RecoverPassword {
  margin: auto;
  padding: 16px;
  max-width: 300px;
  .text {
    font-size: 13px;
    color:var(--light-primary);
  }
  .text-center {
    color: #495057;
  }
  .buttons-auth {
    display: flex;
    flex-direction: column;
  }
  .recover-box {
    padding: 0 24px;
  }
  .check-circle {
    width: 55px;
    height: 55px;
    margin-bottom: 16px;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FCA721;
  }
  .done-icon {
    font-size: 40px;
  }
}
#RecoverForm {
  hr{
    background: $card-bg;
    margin-top: -10px;
  }
  .box-form, .group {
    width: 100%;
  }
}
</style>
