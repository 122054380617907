const _getUserRole = () => {
  const storeUser = JSON.parse(localStorage.getItem('user'))
  if (!storeUser) {
    return null
  }
  const user = storeUser.user

  if (!user) {
    return null
  }
  return user.role
}

export const checkRole = (role) => {
  const userRole = _getUserRole()
  if (!userRole) {
    return false
  }
  return userRole === role
}
