import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginView from '../views/LoginView'
import RecoverPasswordView from '../views/RecoverPasswordView'
import RecoverPasswordFormView from '../views/RecoverPasswordFormView'
import StoreView from '../views/StoreView'
import StoreDetailView from '../views/StoreDetailView'
import UserView from '../views/UserView'
import StoreDeliveryView from '../views/StoreDeliveryView'
import RegionView from '../views/RegionView'
import RegionDetailView from '../views/RegionDetailView'
import MyStoreView from '../views/MyStoreView'
import StoreWithdrawalsView from '../views/StoreWithdrawalsView'
import GroupView from '../views/GroupView'
import MessageView from '../views/MessageView'
import PackageReceiveView from '../views/PackageReceiveView.vue'
import DeliverysView from '../views/DeliverysView'
import DeliveryDetailView from '../views/DeliveryDetailView'
import DeliveryCompleteView from '../views/DeliveryCompleteView'
import TrackingVue from '../views/TrackingVue'
import DeliveryPDF from '../views/DeliveryPDF'
import ScannerInStoreView from '../views/ScannerInStoreView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/tracking',
    name: 'Tracking',
    component: TrackingVue,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/prueba-entrega/:id',
    name: 'DeliveryPDF',
    component: DeliveryPDF,
    meta: {
      isPublic: true,
      hideHeaderAndFooter: true
    }
  },
  {
    path: '/recuperar-password',
    name: 'Recover Password',
    component: RecoverPasswordView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/recuperar-password-form/:token',
    name: 'Recover Password Form',
    component: RecoverPasswordFormView,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/tiendas',
    name: 'StoreView',
    component: StoreView
  },
  {
    path: '/usuarios',
    name: 'UserView',
    component: UserView
  },
  {
    path: '/tiendas/:storeId',
    name: 'StoreDatilView',
    component: StoreDetailView
  },
  {
    path: '/envios-tienda',
    name: 'StoreDeliveryView',
    component: StoreDeliveryView
  },
  {
    path: '/mis-envios',
    name: 'DeliverysView',
    component: DeliverysView
  },
  {
    path: '/mis-envios/:deliveryId',
    name: 'DeliveryDetailView',
    component: DeliveryDetailView
  },
  {
    path: '/retiros-tienda',
    name: 'StoreWithdrawalsView',
    component: StoreWithdrawalsView
  },
  {
    path: '/regiones',
    name: 'RegionView',
    component: RegionView
  },
  {
    path: '/regiones/:regionId',
    name: 'RegionDetailView',
    component: RegionDetailView
  },
  {
    path: '/mi-tienda',
    name: 'MyStoreView',
    component: MyStoreView
  },
  {
    path: '/grupos',
    name: 'GroupView',
    component: GroupView
  },
  {
    path: '/mensajes',
    name: 'MessageView',
    component: MessageView
  },
  {
    path: '/recepcion-de-paquetes/:code',
    name: 'PackageReceiveView',
    component: PackageReceiveView,
    meta: {
      onlyAdmin: true
    }
  },
  {
    path: '/escaner-en-tienda',
    name: 'ScannerInStore',
    component: ScannerInStoreView,
    meta: {
      isPublic: false,
      back: { name: 'Home' },
      title: 'Escaner retiro en tienda',
      roles: ['driver', 'admin']
    }
  },
  {
    path: '/completar-envio',
    name: 'DeliveryCompleteView',
    component: DeliveryCompleteView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.onlyAdmin) {
    const isAdmin = JSON.parse(localStorage.getItem('user')).user.role === 'admin'

    if (isAdmin) {
      // User is authenticated, proceed to the route
      next()
    } else {
      // User is not authenticated, redirect to home
      next('/')
    }
  } else {
    // Non-protected route, allow access
    next()
  }
})

export default router
