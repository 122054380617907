<template lang="pug">
  #store-messages
    .messages(v-if="storeMessages && user.role === 'store'" )
      b-row(v-for="message in storeMessages")
        b-col(v-if="message.is_active")
          card-message(:message="message")
      b-modal(ref='my-modal' hide-footer='' title='Mensajes')
        .d-block.text-center
        b-row(v-for="message in storeMessages")
          b-col(v-if="message.is_active")
            card-message(:message="message")
        b-button.mt-3(variant='outline-danger' block='' @click='hideModal') OK
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CardMessage from './CardMessage.vue'
export default {
  components: {
    CardMessage
  },
  methods: {
    ...mapActions(['getMessageByGroup', 'listMessage', 'listGroup']),
    showModal () {
      this.$refs['my-modal'].show()
    },
    hideModal () {
      this.$refs['my-modal'].hide()
    },
    focusMyElement () {
      this.$refs.focusThis.focus()
    }
  },
  async created () {
    if (this.user.role === 'store') {
      this.$bvModal.hide('confirm-General-Option')
      await this.listMessage()
      await this.listGroup()
    }
  },
  computed: {
    ...mapGetters(['user', 'messages', 'store', 'groups']),
    storeGroups () {
      // Busco grupos de esta tienda
      return this.groups.filter((g) => {
        const groupStoreIds = g.stores.map((s) => s.id)
        return this.user && groupStoreIds.indexOf(this.user.storeId) > -1
      })
    },
    storeMessages () {
      // Filtro mensajes que apliquen para esta tienda
      const filtered = this.messages
        .filter(m => m.is_active)
        .filter(
          (m) => {
            if (m.is_universal) return true // porque son universales
            // o porque seean de un grupo de la tienda
            const messageGroups = m.groups.map(mg => mg.MessageGroup.GroupId)
            console.log({ messageGroups })
            let hasMessage = false
            messageGroups.forEach(groupId => {
              console.log(`${this.storeGroups.map((g) => g.id)}.indexOf(${groupId}) > -1`, this.storeGroups.map((g) => g.id).indexOf(groupId) > -1)
              if (this.storeGroups.map((g) => g.id).indexOf(groupId) > -1) {
                hasMessage = true
              }
            })
            return hasMessage
          }
        )
      return filtered
    }
  },
  watch: {
    storeMessages () {
      if (this.storeMessages.length > 0) this.$refs['my-modal'].show()
    }
  }
}
</script>
<style lang="scss" scoped>
#store-messages {
  .messages {
    display: flex;
    flex-direction: column;
  }
}

</style>
