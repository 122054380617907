<template lang="pug">
  #withdrawal-form
    b-container
      center.mb-3
        .title.mb-3 Generar Nuevo Retiro
        .steps.d-inline-flex.align-items-baseline
          div
            b-avatar.mx-3.mb-2(size="3em"
            :variant="step === 1 ? 'status-assigned' : 'status-approved'"
            icon="-" active-class="activo"
            active
            )
              .number 1
            div(:style="step === 1 ? 'color:var(--status-assigned)' : 'color:var(--status-approved)'")
              strong Fecha de retiro
                div Datos de la Tienda
          b-avatar.circle(
            size="1.1em"
            v-for="i in 5"
            :variant="step === 2 || step === 3? 'status-approved' : 'muted'"
            icon="-"
            style="height: 11px"
            v-if="width > 768"
            )
          div
            b-avatar.mx-3.mb-2(
              size="3em"
              :variant="step === 2 ? 'status-assigned' : (step ===3 ? 'status-approved': 'muted')"
              icon="-"
              active-class="activo"
              )
              .number 2
            div(:style="step === 2 ? 'color:var(--status-assigned)' : (step ===3 ? 'color:var(--status-approved)': 'color:#495057')")
              strong Datos de envío
          b-avatar.circle(
            size="1.1em"
            v-for="i in 5"
            :variant="step === 3 ? 'status-approved' : 'muted'"
            icon="-"
            style="height: 11px"
            v-if="width > 768"
            )
          div
            b-avatar.mx-3.mb-2(
              size="3em"
              :variant="step === 3 ? 'status-assigned' : 'muted'"
              icon="-"
              active-class="activo"
              )
              .number 3
            div(:style="step === 3 ? 'color:var(--status-assigned)' : 'color:#495057'")
              strong Datos de pago
      .box(v-if="user.role === 'admin'")
        div.mb-3(v-if="step === 1" style="width:300px")
          h5 Selecciona Tienda
          .box-form
            b-form-group.input-title(
              label-for="withdrawal-store-input"
              invalid-feedback="Este campo es requerido"
            )
              b-form-select#withdrawal-store-input.input(
                v-model="storeSelectedId"
                :options="listStore"
                placeholder="Ingresa una Tienda"
                @change= "selectStore()"
              )
      store-data(v-if="step === 1" @continue="nextStage")
      shipping-data(v-if="step === 2" @continue="nextStage")
      payment-data(v-if="step === 3"  @continue="nextStage")

</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import StoreData from './FormSteps/StoreData.vue'
import ShippingData from './FormSteps/ShippingData.vue'
import PaymentData from './FormSteps/PaymentData.vue'

export default {
  components: {
    StoreData,
    ShippingData,
    PaymentData
  },
  data () {
    return {
      storeSelectedId: '',
      stores: [],
      step: 1,
      width: 0
    }
  },
  mounted () {
    this.getStores()
    this.width = document.documentElement.clientWidth
    window.onresize = () => {
      this.width = document.documentElement.clientWidth
    }
  },
  computed: {
    ...mapGetters(['withdrawal', 'user', 'store']),
    listStore () {
      return this.stores.map(i => {
        return {
          text: i.name + ' - ' + i.storeName,
          value: i.id
        }
      })
        .sort((a, b) => a.text && b.text ? a.text.toLowerCase() >= b.text.toLowerCase()
          ? 1 : -1
          : -1)
        .concat({
          text: 'Selecciona una Tienda',
          value: null
        })
    }
  },
  methods: {
    ...mapActions(['getStore', 'listAllStoreIdName']),
    async selectStore () {
      const idstore = this.storeSelectedId
      this.Storeget(idstore)
    },
    async getStores () {
      const stores = await this.listAllStoreIdName()
      console.log(stores.length, 'stores arrived')
      this.stores = stores
    },
    nextStage (event) {
      if (event) this.step += 1
      else this.step -= 1
    },
    async Storeget (id) {
      await this.getStore({ storeId: id })
    }
  },
  async created () {
    if (this.user.role !== 'admin') {
      this.Storeget(this.user.storeId)
    }
  }
}
</script>
<style lang="scss">
#withdrawal-form {
  .card-info {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    opacity: 1;
  }
  .title{
    margin-top: -52px;
    font-size: 23px;
    color: #495057;
  }
  .circle {
    margin: 2px;
  }
  .number {
    color: white;
    font-size: 23px;
    font-weight: 100;
  }
  .subtitle {
    font-size:14px;
    margin-top: -10px
  }
}
</style>
