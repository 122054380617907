<template lang="pug">
  #shipping-data(v-if="packages")
    .table-header.d-flex.justify-content-between
      div
        h5 Datos de Envío
      div.d-inline-flex.align-items-center
        b-button(variant="status-approved" size="sm" href="PlantillaCargaMasiva.xlsx") Descargar plantilla
        action-upload-data(@done="addPackeges" :communesOptions="communesOptions" @loading="loading = $event")
    b-table(:fields="tableFields" :items="packages" responsive :busy="loading")
      template(v-slot:table-busy)
        .text-center.my-2
          b-spinner.align-middle
          strong.ml-2 Cargando Envíos...
      template(v-slot:cell(numero)="{ index }")
        div {{getIndex(index)}}
      template(v-slot:cell(customerName)="{ item, index }")
        b-form-group(invalid-feedback="Este campo es requerido")
          b-form-input(v-bind:disabled="item.isdisabled" v-model="item.customerName" size="sm" :state="$v.$dirty ? !$v.packages.$each[index].customerName.$invalid : null")
      template(v-slot:cell(customerPhone)="{ item, index }")
        b-form-group(invalid-feedback="Este campo es requerido")
          b-form-input(v-bind:disabled="item.isdisabled" v-model="item.customerPhone" size="sm" :state="$v.$dirty ? !$v.packages.$each[index].customerPhone.$invalid : null")
      template(v-slot:cell(customerAddress)="{ item, index }")
        b-form-group(invalid-feedback="Este campo es requerido")
          b-form-input(v-bind:disabled="item.isdisabled" v-model="item.customerAddress" size="sm" :state="$v.$dirty ? !$v.packages.$each[index].customerAddress.$invalid : null")
      template(v-slot:cell(customerEmail)="{ item }")
        b-form-input(v-bind:disabled="item.isdisabled" v-model="item.customerEmail" size="sm")
      template(v-slot:cell(customerCommune)="{ item, index }")
        b-form-group(invalid-feedback="Este campo es requerido")
          b-form-select(v-bind:disabled="item.isdisabled" :options="communesOptions" v-model="item.commune" size="sm" :state="$v.$dirty ? !$v.packages.$each[index].commune.$invalid : null")
      template(v-slot:cell(tags)="{ item, index }")
        b-form-group.tags-container
          b-form-select(v-bind:disabled="item.isdisabled" :options="tagsOptions" v-model="item.tags" size="sm")
      template(v-slot:cell(size)="{ item, index }")
        b-form-group(invalid-feedback="Este campo es requerido")
          b-form-select(v-bind:disabled="item.isdisabled" :options="sizeOptions" v-model="item.size" size="sm" :state="$v.$dirty ? !$v.packages.$each[index].size.$invalid : null")
      template(v-slot:cell(shippingCost)="{ item, index }")
        .font-weight-bold {{calculateItem(item.size, item.commune, index)}}
      template(v-slot:cell(observations)="{ item }")
        b-form-group(:invalid-feedback='rules.length.invalid(500)')
          b-form-input(v-bind:disabled="item.isdisabled" v-model="item.observations" size="sm" :state="rules.length.maxLength(item.observations, 500)")
      template(v-slot:cell(isAdditional)="{ item }")
        b-form-checkbox(v-model="item.isAdditional")
      template(v-slot:cell(edit)="{ index }")
        .material-icons.icon(style="color:var(--status-rejected)" @click="editItem(index)") edit
      template(v-slot:cell(delete)="{ index }")
        .material-icons.icon(style="color:var(--status-rejected)" @click="deleteItem(index)") highlight_off
    .buttons.d-flex.justify-content-between(style="font-size:15px;font-weight:500")
      div.d-inline-flex.align-items-center(style="color:var(--status-assigned);cursor:pointer" @click="agregarPaquete")
        .material-icons.icon(style="color:var(--status-assigned)") add_box
        span.mx-2 Agregar Envío
      div.d-inline-flex.align-items-center(style="color:var(--status-rejected);cursor:pointer" @click="deleteAllItems()") Eliminar Todos
    hr.my-4.linea
    //- .subtotales.text-right.align-items-end
    b-container.text-right.align-items-end
      b-row(v-for="t in getSubtotals" :key="t.region")
        b-col(cols="2" offset-md="8") {{t.region}}:
        b-col ${{t.total.toLocaleString('es-CL')}}({{t.paquetes}})
      b-row.font-weight-bold
        b-col(cols="2" offset-md="8") Retiro:
        b-col {{calculateWithdrawalCost === 0 ? 'Gratis' : `$${calculateWithdrawalCost.toLocaleString('es-CL')}`}}
      b-row.font-weight-bold(v-if="calculateVolumeDiscount > 0")
        b-col(cols="2" offset-md="8")  Dcto. volumen:
        b-col - ${{calculateVolumeDiscount.toLocaleString('es-CL')}}
      b-row.font-weight-bold
        b-col(cols="2" offset-md="8") Total:
        b-col ${{calculateTotal.toLocaleString('es-CL')}}
    .footer.d-flex.justify-content-between.my-4
      b-button(variant="outline-primary" size="sm" @click="$emit('continue', false)") Atrás
      b-button(variant="status-approved" size="sm" @click="save()") Continuar
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import MakeToastMixin from '../../mixins/MakeToastMixin.vue'
import ActionUploadData from './Actions/ActionUploadData.vue'
import calculateSubtotals from '../../../utils/calculateSubtotals'
import calculateWithdrawalCostUtil from '../../../utils/caclulateWithdrawalCost'

export default {
  name: 'ShippingData',
  mixins: [MakeToastMixin],
  components: {
    ActionUploadData
  },
  data () {
    return {
      specialPrice: [],
      specialPriceItem: [],
      subtotals: [],
      packages: [],
      sameDayPackages: [],
      sameDayTotalRetirementPrice: 0,
      tableFields: [
        { key: 'numero', label: 'N°' },
        { key: 'customerName', label: 'Nombre del destinatario' },
        { key: 'customerPhone', label: 'Teléfono del destinatario' },
        { key: 'customerAddress', label: 'Dirección de destino' },
        { key: 'customerCommune', label: 'Comuna de destino' },
        { key: 'observations', label: 'Observaciones' },
        { key: 'customerEmail', label: 'Correo (opcional)' },
        { key: 'tags', label: 'Tags' },
        { key: 'size', label: 'Tamaño del paquete' },
        { key: 'shippingCost', label: 'Costo de envío' },
        { key: 'edit', label: '' },
        { key: 'delete', label: '' }
      ],
      tableAdminFields: [
        // { key: 'isAdditional', label: 'Adicional' }
      ],
      loading: false,
      AllCommunes: [],
      communes: [],
      rules: {
        length: {
          maxLength: (v, max) => v.length <= max,
          invalid: v => `Excede el límite de ${v} caracteres`
        }
      }
    }
  },
  validations: {
    packages: {
      $each: {
        customerName: { required },
        customerPhone: { required },
        customerAddress: { required },
        commune: { required },
        size: { required }
      }
    }
  },
  methods: {
    ...mapActions(['listAllCommunes', 'listAllActiveCommunes', 'listRegion', 'getRegionStore', 'getRegionsStore', 'listWithdrawals', 'getSameDateWithdrawals']),
    async calcualteItemSpecial (idRegion) {
      const RoleUser = JSON.parse(localStorage.user).user.role
      const idStore = RoleUser !== 'admin' ? JSON.parse(localStorage.user).user.storeId : this.store.id
      const PreciosEspeciales = await this.getRegionStore({ idRegion: idRegion, idStore: idStore })
      this.specialPriceItem = PreciosEspeciales
    },
    async calculatepricespecial () {
      if (this.communes.length > 0 && this.commune) {
        const idRegion = this.commune.regionId
        const RoleUsuario = JSON.parse(localStorage.user).user.role
        let idStore = 0
        if (RoleUsuario !== 'admin') {
          idStore = JSON.parse(localStorage.user).user.storeId
        } else {
          idStore = this.store.id
        }
        const PreciosEspeciales = await this.getRegionStore({ idRegion: idRegion, idStore: idStore })
        if (PreciosEspeciales.status === 404) {
          return false
        } else if (PreciosEspeciales.status === 200 | PreciosEspeciales.status === 201) {
          this.specialPrice = PreciosEspeciales.data
          return true
        }
      }
      return false
    },
    async getSameDayPackages (storeId) {
      /** Busca paquetes de retiros del mismo día porque afectará el cálculo por retiro */
      try {
        const retirementDate = localStorage.getItem('retirementDate')
        // const res = await this.listWithdrawals({ params: { retirementDay, storeId } })
        const sameDateRetirements = await this.getSameDateWithdrawals({ params: { retirementDate, storeId } })
        sameDateRetirements.forEach(r => {
          this.sameDayTotalRetirementPrice += r.retirementPrice
          this.sameDayPackages = this.sameDayPackages.concat(r.packages)
        })
      } catch (err) {
        console.log('error geting other days packages', err)
      }
    },
    save () {
      const storeMin = this.store.noRequiresMin
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else if (this.packages.length < 3 & storeMin === false) {
        this.makeDangerToast('Debe ingresar por lo menos 3 paquetes')
      } else {
        if (this.packages.length < 1) {
          this.makeDangerToast('Debe ingresar por lo menos 1 paquetes')
        } else {
          localStorage.setItem('packages', JSON.stringify(this.packages))
          localStorage.setItem('total', this.calculateTotal.toLocaleString('es-CL'))
          localStorage.setItem('retirementPrice', this.calculateWithdrawalCost.toLocaleString('es-CL'))
          localStorage.setItem('volumeDiscount', this.calculateVolumeDiscount.toLocaleString('es-CL'))
          this.$emit('continue', true)
        }
      }
    },
    agregarPaquete () {
      this.packages.push({
        withdrawalId: null,
        customerName: '',
        customerPhone: '',
        customerAddress: '',
        customerEmail: '',
        customerCommune: null,
        commune: null,
        tags: null,
        size: null,
        shippingCost: 0,
        observations: '',
        isdisabled: false,
        isAdditional: false
      })
      if (this.packages.length > 1) {
        const numero = parseInt(this.packages.length - 2)
        this.packages[numero].isdisabled = true
      }
      localStorage.setItem('packages', JSON.stringify(this.packages))
    },
    addPackeges (event) {
      this.packages = this.packages.concat(event)
      localStorage.setItem('packages', JSON.stringify(this.packages))
    },
    calculateItem (size, commune, index) {
      let total = 0
      if (size && commune) {
        const region = this.regions.filter(region => region.id === commune.regionId)
        let totalEspecial = 0
        for (const precioRegion of this.specialPriceItem) {
          if (precioRegion.idRegion === region[0].id) {
            totalEspecial = size === 'small' ? precioRegion.smallShippingPrice : precioRegion.mediumShippingPrice
          }
        }
        const totalnormal = size === 'small' ? region[0].smallShippingPrice : region[0].mediumShippingPrice
        // eslint-disable-next-line no-debugger
        total = totalEspecial || totalnormal
        this.packages[index].shippingCost = total
      }
      return `$${total.toLocaleString('es-CL')}`
    },
    deleteItem (index) {
      this.packages.splice(index, 1)
      localStorage.setItem('packages', JSON.stringify(this.packages))
      const numero = parseInt(this.packages.length - 1)
      this.packages[numero].isdisabled = false
    },
    editItem (index) {
      if (this.packages.length > 0) {
        this.packages.forEach(p => {
          p.isdisabled = true
        })
      }
      this.packages[index].isdisabled = false
    },
    deleteAllItems () {
      this.packages.splice(0, this.packages.length)
      localStorage.setItem('packages', JSON.stringify([]))
    },
    getIndex (index) {
      return (index + 1).toString().padStart(3, 0)
    }
  },
  computed: {
    ...mapGetters(['withdrawal', 'store', 'regions']),
    communesOptions () {
      return this.communes.map(commune => {
        return { value: commune, text: commune.name }
      })
        .filter(c => c.value.region !== null)
        .concat({ value: null, text: 'Seleccionar' })
    },
    sizeOptions () {
      return [
        { value: null, text: 'Seleccionar' },
        { value: 'small', text: 'Pequeño' },
        { value: 'medium', text: 'Mediano' }]
    },
    tagsOptions () {
      return [
        { value: null, text: 'N/A' },
        { value: 'exchange', text: 'Cambio' },
        { value: 'return', text: 'Devolución' }
      ]
    },
    totalPackegesByDate () {
      return this.packages.length + this.sameDayPackages.length
    },
    calculateVolumeDiscount () {
      return this.totalPackegesByDate >= 10 && this.commune.type === 'urban' ? this.sameDayTotalRetirementPrice : 0
    },
    calculateTotal () {
      let total = 0
      total += this.calculateWithdrawalCost
      if (this.packages.length > 0) {
        this.packages.forEach(p => {
          total += p.shippingCost
        })
      }
      if (this.totalPackegesByDate >= 10) {
        total -= this.sameDayTotalRetirementPrice
      }
      return total < 0 ? 0 : total
    },
    commune () {
      return this.AllCommunes.find(commune => commune.id === this.store.withdrawalCommuneId)
    },
    ruralRetirementPrice () {
      if (!this.commune) return null
      return this.specialPrice && this.specialPrice.ruralRetirementPrice ? this.specialPrice.ruralRetirementPrice : this.commune.region.ruralRetirementPrice
    },
    urbanRetirementPrice () {
      if (!this.commune) return null
      return this.specialPrice && this.specialPrice.urbanRetirementPrice ? this.specialPrice.urbanRetirementPrice : this.commune.region.urbanRetirementPrice
    },
    calculateWithdrawalCost () {
      // eslint-disable-next-line no-debugger
      return calculateWithdrawalCostUtil(
        {
          communes: this.AllCommunes,
          commune: this.commune,
          packages: this.packages.concat(this.sameDayPackages),
          ruralRetirementPrice: (typeof this.specialPrice.ruralRetirementPrice === 'undefined' || this.specialPrice.ruralRetirementPrice === null)
            ? this.ruralRetirementPrice : this.specialPrice.ruralRetirementPrice,
          urbanRetirementPrice: (typeof this.specialPrice.urbanRetirementPrice === 'undefined' || this.specialPrice.urbanRetirementPrice === null)
            ? this.urbanRetirementPrice : this.specialPrice.urbanRetirementPrice
        }
      )
    },
    getSubtotals () {
      const data = calculateSubtotals(this.packages)
      if (data.length > 0) {
        return data.sort((a, b) => a.region.localeCompare(b.region)) // eslint-disable-line
      }
      return ''
    }
  },
  async created () {
    const RoleUser = JSON.parse(localStorage.user).user.role
    const idStore = RoleUser !== 'admin' ? JSON.parse(localStorage.user).user.storeId : this.store.id
    const preciosTienda = await this.getRegionsStore({ idStore: idStore })
    this.specialPriceItem = preciosTienda.data
    this.AllCommunes = await this.listAllCommunes()
    this.communes = await this.listAllActiveCommunes()
    await this.listRegion()
    await this.getSameDayPackages(idStore)
    this.packages = localStorage.getItem('packages') ? JSON.parse(localStorage.getItem('packages')) : []
    this.calculatepricespecial()

    if (RoleUser === 'admin') {
      const editFields = this.tableFields.slice(-2)
      this.tableFields = this.tableFields.slice(0, -2)
      this.tableFields.push(...this.tableAdminFields)
      this.tableFields.push(...editFields)
    }
  }
}
</script>
<style lang="scss">
#shipping-data{
  .linea {
    border: 1px solid #00000029;
    opacity: 1;
  }
  input, select{
    background-color: #FEFFEE;
  }
  .icon {
    cursor:pointer;
    font-size: 25px;
  }
  .subtotales{
    display: grid;
    justify-content: end;
  }
  .tags-container {
    min-width: 80px;
  }
}
</style>
