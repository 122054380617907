const mutations = {
  'SET_COMMUNES' (state, value) {
    state.communes = value
  },
  'SET_COMMUNE' (state, value) {
    state.commune = value
  },
  'SET_ALL_COMMUNES' (state, value) {
    state.allCommunes = value
  }
}
export default mutations
