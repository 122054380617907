import Vue from 'vue'
import Vuex from 'vuex'
import store from './store'
import user from './user'
import auth from './auth'
import delivery from './delivery'
import region from './region'
import commune from './commune'
import withdrawal from './withdrawal'
import message from './message'
import group from './group'
import regionStore from './regionStore'
import packageStore from './package'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    store,
    user,
    auth,
    delivery,
    region,
    commune,
    withdrawal,
    message,
    group,
    regionStore,
    packageStore
  },
  plugins: [createPersistedState()]
})
