<template lang="pug">
b-container#StoreWithdrawalsView(fluid="lg")
  ListWithdrawals
</template>
<script>
import ListWithdrawals from '../components/withdrawal/ListWithdrawals'
export default {
  components: {
    ListWithdrawals
  }
}
</script>
