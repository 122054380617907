import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp, faWaze } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faCopy } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faWhatsapp,
  faWaze,
  faMapMarkerAlt,
  faCopy
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
