import axios from '../../plugins/axios'

export const listGroup = ({ commit }, { params } = {}) => {
  return axios.get('/group', { params })
    .then((response) => {
      console.log('groups', response.data)
      commit('SET_GROUPS', response.data)
      return response
    })
    .catch(err => err.response)
}

export const createGroup = (_, { data }) => {
  return axios.post('/group', data)
    .then(response => response)
    .catch(err => err.response)
}

export const getGroup = ({ commit }, { groupId } = {}) => {
  return axios.get(`group/${groupId}`)
    .then((response) => {
      commit('SET_GROUP', { group: response.data })
      return response
    })
    .catch(err => err.response)
}

export const deleteGroup = (_, { groupId }) => {
  return axios.delete(`/group/${groupId}`)
    .then(response => response)
    .catch(err => err.response)
}

export const updateGroup = (_, { groupId, data }) => {
  return axios.put(`/group/${groupId}`, data)
    .then(response => response)
    .catch(err => err.response)
}

export const listAllGroupName = (_, { params } = {}) => {
  return axios.get('all-groups', { params })
    .then((res) => {
      return res.data
    })
    .catch(err => err.response)
}
