<template lang="pug">
b-container(fluid="lg")
  tracking
</template>

<script>
import Tracking from '../components/delivery/Tracking.vue'
export default {
  components: {
    Tracking
  }
}
</script>

<style lang="scss" scoped>
</style>
