<template lang="pug">
  #select-options-withdrawals(v-if="store")
    b-form-group(style="min-width: 200px")
      b-form-radio(v-model="selected" value="normal" @change="change") Hasta las 23:59 p.m
      b-form-radio(v-model="selected" value="accept" @change="change") Hasta las 9:59 a.m
      b-form-radio(v-model="selected" value="reject" @change="change") Rechazar siempre
      b-form-radio(v-model="selected" value="unlimited" @change="change") Sin restricción
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    store: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    ...mapGetters(['statusStores'])
  },
  methods: {
    ...mapActions(['updateStore']),
    setSelected (value) {
      if (value.alwaysAcceptWithdrawals) this.selected = 'accept'
      if (value.alwaysRejectWithdrawals && !value.alwaysAcceptWithdrawals) this.selected = 'reject'
      if (!value.alwaysAcceptWithdrawals && !value.alwaysRejectWithdrawals) this.selected = 'normal'
      if (value.alwaysAcceptWithdrawals && value.alwaysRejectWithdrawals) this.selected = 'unlimited'
    },
    async change (value) {
      const data = {
        alwaysAcceptWithdrawals: false,
        alwaysRejectWithdrawals: false
      }
      if (value === 'accept') {
        data.alwaysAcceptWithdrawals = true
        data.alwaysRejectWithdrawals = false
      } else if (value === 'reject') {
        data.alwaysRejectWithdrawals = true
        data.alwaysAcceptWithdrawals = false
      } else if (value === 'unlimited') {
        data.alwaysAcceptWithdrawals = true
        data.alwaysRejectWithdrawals = true
        data.status = value
      }
      await this.updateStore({ storeId: this.store.id, data })
    }
  },
  created () {
    this.setSelected(this.store)
  },
  watch: {
    store (value) {
      this.setSelected(value)
    }
  }
}
</script>
