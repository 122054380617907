<template lang="pug">
  b-form(@submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input location_on
      b-form-group.input-title(
        label-for="name-input"
        label="Nombre"
        invalid-feedback="Este campo es requerido"
      )
        b-form-input#name-input.input(
          v-model="form.name"
          maxlength="100"
          :state="$v.$dirty ? !$v.form.name.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input location_city
      b-form-group.input-title(
        label="Tipo"
        label-for="type-input"
        invalid-feedback="Este campo es requerido"
      )
        b-form-select#type-input.input(
          v-model="form.type"
          :options="typesOptions"
          :state="$v.$dirty ? !$v.form.type.$invalid : null"
        )
    hr.linea
    .box-form
      b-form-group.input-title(
        label="Activo"
        label-for="active-input"
      )
        b-form-checkbox#active-input.input(
          v-model="form.active"
        )
    .text-right
      b-button(type="submit") Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    commune: {
      type: Object,
      default: null
    },
    editMode: Boolean
  },
  data () {
    return {
      form: {
        name: this.editMode ? this.commune.name : '',
        code: this.editMode ? this.commune.code : '',
        type: this.editMode ? this.commune.type : null,
        active: this.editMode ? this.commune.active : true,
        regionId: this.editMode ? this.commune.regionId : this.$route.params.regionId
      }
    }
  },
  validations () {
    const form = {
      name: { required },
      type: { required }
    }
    return { form }
  },
  methods: {
    ...mapActions(['createCommune', 'updateCommune', 'getCommune']),
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          this.onUpdateCommune()
        } else {
          this.onCreateCommune()
        }
      }
    },
    async onCreateCommune () {
      const response = await this.createCommune({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('La comuna se creó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear la comuna')
      }
    },
    async onUpdateCommune () {
      const response = await this.updateCommune({ id: this.commune.id, data: this.form })
      if (response.status === 200) {
        this.makeSuccessToast('La comuna se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar la comuna')
      }
    }
  },
  computed: {
    typesOptions () {
      return [
        { value: null, text: 'Seleccionar' },
        { value: 'urban', text: 'Urbana' },
        { value: 'rural', text: 'Rural' }
      ]
    }
  }
}
</script>
<style scoped>
.box-form {
  align-items: center;
}
</style>
