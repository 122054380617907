<template lang="pug">
.tabla.sombra
  b-row.mb-2(align-h="between" no-gutters style="gap: 10px")
    b-col.mb-2
      h3.d-inline Tiendas
    b-col(md="auto")
      b-button(@click="fetchData(true)" :disabled="loading" block)
        b-spinner.mr-2(v-if="loading" small variant="white")
        span Sincronizar
    b-col(md="auto")
      b-button(:disabled="loading" block  @click="$bvModal.show('add-store')" variant="status-approved")
        span Agregar Tienda
  b-row.mb-2
    b-col(md="8")
      b-form(inline @submit.stop.prevent="fetchData()")
        b-form-input(v-model="backendFilters.search" placeholder="Buscar"  class="mb-2 mr-sm-2 mb-sm-0")
        b-form-select#withdrawal-store-input.input(
          v-model="backendFilters.selected"
          :options="options"
          placeholder="Retiro"
          class="mb-2 mr-sm-2 mb-sm-0"
        )
        b-button(@click="fetchData()" variant="outline-primary") Filtrar
  b-row.mb-2
  b-table(:items="storesItems" :fields="fields" responsive :busy="loadingTable")
    template(v-slot:table-busy)
      .text-center.my-2
        b-spinner.align-middle
    template.d-flex(v-slot:cell(pickupPhone1)="{ item }")
      div {{ item.pickupPhone1 }}
        span(v-if="item.pickupPhone2")  / {{ item.pickupPhone2 }}
    template.d-flex(v-slot:cell(name)="{ item }")
      span(:class="item.active ? '' : 'deactive-store'") {{ item.name }}
    template.d-flex(v-slot:cell(actions)="{ item }")
        .material-icons-outlined.btn-icon.mr-2(@click="$router.push('/tiendas/' + item.id)") edit
        .material-icons-outlined.btn-icon.text-danger(@click="deleteStore(item.id)") delete
    //- Opciones de retiro individual
    template.d-flex(v-slot:cell(withdrawals)="{ item }")
      select-options-withdrawals(:store="item")
  .d-flex.justify-content-center
    b-pagination.m-auto(
      v-model="backendFilters.page"
      :total-rows="countRows"
      :per-page="backendFilters.size"
      @change="backendFilters.page = $event, fetchData()"
      aria-controls="my-table"
    )
    //- Modal de confirmacion
    b-modal#confirm-General-Option(hide-footer='')
      template(#modal-title='') ¿Seguro desea realizar este cambio?
      .d-block.text-center
        h5.mt-4.mb-5   {{confirmGeneralOption.name}}
        b-button.mt-3(block='' @click="$bvModal.hide('confirm-General-Option'), change(confirmGeneralOption.type)") Confirmar
    //- Modal para agregar tienda
    ModalAddStore
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SelectOptionsWithdrawals from './SelectOptionsWithdrawals.vue'
import ModalAddStore from './ModalAddStore.vue'

export default {
  components: {
    SelectOptionsWithdrawals,
    ModalAddStore
  },
  data () {
    return {
      options: [
        { value: null, text: 'Todos Horarios' },
        { value: 'normal', text: 'Hasta las 23:59 p.m' },
        { value: 'accept', text: 'Hasta las 9:59 a.m' },
        { value: 'reject', text: 'Rechazar siempre' },
        { value: 'unlimited', text: 'Sin restricción' }
      ],
      confirmGeneralOption: {
        name: null,
        type: null
      },
      loadingTable: false,
      loading: false,
      backendFilters: {
        page: 1,
        size: 30,
        search: '',
        selected: null,
        businessId: undefined
      },
      countRows: 30,
      fields: [
        {
          label: 'Código Tienda',
          key: 'internalCode',
          sortable: false
        },
        {
          label: 'Nombre',
          key: 'name',
          sortable: true
        },
        {
          label: 'Encargado de Retiro',
          key: 'withdrawalAdgent',
          sortable: true
        },
        {
          label: 'Teléfono',
          key: 'pickupPhone1'
        },
        {
          label: 'Dirección de Retiro',
          key: 'withdrawalAddress'
        },
        {
          label: 'Comuna de Retiro',
          key: 'commune.name',
          sortable: true
        },
        {
          label: 'Usuarios',
          key: 'emailSummary',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Acciones'
        },
        {
          key: 'withdrawals',
          label: 'Restricciones de horario'
        }
      ]
    }
  },
  async created () {
    this.getStatusStore()
    this.fetchData()
  },
  computed: {
    ...mapGetters(['stores', 'statusStores', 'user']),
    storesItems () {
      return this.stores.map(s => {
        const item = { ...s }
        item.emailSummary = ''

        if (item.users.length) {
          item.emailSummary = item.users[0].email
          if (item.users.length >= 2) {
            item.emailSummary += ' ...'
          }
        }
        return item
      })
    }
  },
  methods: {
    ...mapActions(['listStores', 'updateAllStores', 'getStatusStore', 'updateStatusStore']),
    async fetchData (sync = false) {
      this.loadingTable = true
      this.loading = sync
      const response = await this.listStores({ params: { ...this.backendFilters, sync } })
      this.loading = false
      this.loadingTable = false
      if (response.ok) {
        this.countRows = response.data.count
      }
    },
    async deleteStore (storeId) {
      const store = (await this.$store.dispatch('getStoreCountDeliveries', { storeId })).data

      const ok = await this.$bvModal.msgBoxConfirm(`¿Seguro desea eliminar esta tienda?, se eliminarán ${store.deliveriesCount} envíos y ${store.withdrawalsCount} retiros`, {
        title: 'Eliminar tienda',
        okVariant: 'danger',
        cancelVariant: 'outline-primary',
        okTitle: 'Eliminar',
        cancelTitle: 'Cancelar'
      })
      if (!ok) return
      try {
        await this.$store.dispatch('deleteStore', { storeId: store.id })
        this.$bvToast.toast('Tienda eliminada correctamente', {
          title: 'Éxito',
          variant: 'success',
          solid: true
        })
        this.fetchData()
      } catch (error) {
        this.$bvToast.toast('Error al eliminar la tienda', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }
    },
    async change (value) {
      const data = {
        status: value
      }
      await this.updateStatusStore({ statusId: this.statusStores.id, data })
      this.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.deactive-store {
  text-decoration: line-through
}
</style>
