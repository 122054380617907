<template lang="pug">
  div.ml-3
    input.d-none(ref="inputFile" type="file" name="file" @change="importData")
    b-button(@click="() => this.$refs.inputFile.click()" variant="status-approved" size="sm") Adjuntar carga masiva
</template>
<script>
import readXlsxFile from 'read-excel-file'
export default {
  props: ['communesOptions'],
  data () {
    return {
      packages: [],
      tagsNames: {
        FLEX: 'flex',
        CAMBIO: 'exchange',
        DEVOLUCIÓN: 'return'
      },
      schema: {
        TIENDA: {
          prop: 'storeName',
          type: String,
          required: true
        },
        'NOMBRE DEL DESTINATARIO': {
          prop: 'customerName',
          type: String
        },
        'TELÉFONO DEL DESTINATARIO': {
          prop: 'customerPhone',
          type: String,
          required: true
        },
        'DIRECCIÓN DE DESTINO': {
          prop: 'customerAddress',
          type: String,
          required: true
        },
        'COMUNA DE DESTINO': {
          prop: 'customerCommune',
          type: String,
          required: true
        },
        OBSERVACIONES: {
          prop: 'observations',
          type: String
        },
        CORREO: {
          prop: 'customerEmail',
          type: String
        },
        'TAMAÑO DEL PAQUETE': {
          prop: 'size',
          type: String
        },
        TAGS: {
          prop: 'tags',
          type: String
        }
      }
    }
  },
  methods: {
    getCommune (name) {
      const result = this.communesOptions.filter(commune => this.normalizeText(commune.text) === this.normalizeText(name))
      return result.length > 0 ? result[0].value : null
    },
    normalizeText (text) {
      text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      return text.toLowerCase()
    },
    importData (e) {
      this.$emit('loading', true)
      try {
        readXlsxFile(e.target.files[0], { schema: this.schema, sheet: 1 })
          .then(({ rows, errors }) => {
            // console.log('read', rows, errors)
            rows.forEach(row => {
              // console.log(row, 'row')
              const pack = {
                withdrawalId: null,
                customerName: row.customerName !== undefined ? row.customerName : '',
                customerPhone: row.customerPhone !== undefined ? row.customerPhone : '',
                customerAddress: row.customerAddress !== undefined ? row.customerAddress : '',
                customerEmail: row.customerEmail !== undefined ? row.customerEmail : '',
                commune: row.customerCommune !== undefined ? this.getCommune(row.customerCommune) : null,
                size: row.size === 'PEQUEÑO' ? 'small' : (row.size === 'MEDIANO' ? 'medium' : null),
                tags: row.tags ? this.tagsNames[row.tags] : null,
                shippingCost: 0,
                observations: row.observations !== undefined ? row.observations : ''
              }
              if (pack.customerName === '' && pack.customerPhone === '' && pack.customerAddress === '') {
                row++
              } else {
                this.packages.push(pack)
              }
            })
            this.$emit('done', JSON.parse(JSON.stringify(this.packages)))
            this.packages = []
          })
      } catch (err) {
        console.log(err)
      }
      this.$emit('loading', false)
    }
  }
}
</script>
