import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const RegionStore = {
  state: {
    regions: [],
    region: {}
  },
  getters,
  mutations,
  actions
}

export default RegionStore
