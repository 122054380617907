<template lang="pug">
  #list-deliveries
    b-modal#withdrawal-detail(hide-footer size="md" @hide="handleHide")
      template(v-slot:modal-title)
        h4 Recepción de paquetes
      b-card(bg-variant="dark" :header="packageCode" text-variant="white" class="text-center")
        b-card-text(class="text-left ml-4 mb-1 mt-3") <strong> Tienda:</strong> {{ packageData.store }}
        b-card-text(class="text-left ml-4 mb-1") <strong>Cliente:</strong> {{ packageData.customer }}
        b-card-text(class="text-left ml-4 mb-1") <strong>Teléfono:</strong> {{ packageData.phone }}
        b-card-text(class="text-left mx-4 mb-1") <strong>Dirección:</strong> {{ packageData.address }}
        b-card-text(class="text-left mx-4 mb-1") <strong>Email:</strong> {{ packageData.email }}
        b-card-text(class="text-left mx-4 mb-1") <strong>Comuna:</strong> {{ packageData.commune }}
        b-card-text(class="text-left mx-4 mb-1") <strong>Tamaño del paquete:</strong> {{ packageData.size }}
        b-card-text(class="text-left mx-4 mb-1") <strong>Costo de envío:</strong> {{ packageData.shippingCost }}
        //- b-card-text(class="text-left mx-4 mb-1") <strong>Adicional:</strong> {{  packageData.isAdditional }}
        b-card-text(class="text-center mx-4 mt-3") <strong>Recepcionado:</strong>
          b-form-checkbox(switch v-model="packageData.receptionStatus" @change="updateReception" size="lg")
        b-card-text(class="text-left mx-4 mt-4 mb-2") <strong>Observaciones:</strong> {{ packageData.observations }}

</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: {
    selectedPackage: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      packageCode: null,
      recepcionado: true
    }
  },
  mounted () {
    const packageCode = location.pathname.split('/')[2]
    this.packageCode = packageCode
    this.$bvModal.show('withdrawal-detail')
  },
  computed: {
    packageData () {
      const p = this.selectedPackage
      const sizeCodes = {
        small: 'Pequeño',
        medium: 'Mediano'
      }
      return {
        store: p.withdrawal?.store?.name,
        customer: p.customerName,
        phone: p.customerPhone,
        address: p.customerAddress,
        email: p.customerEmail,
        commune: p.commune?.name,
        size: sizeCodes[p.size],
        shippingCost: `$${p.shippingCost?.toLocaleString('es-CL')}`,
        observations: p.observations,
        isCanceled: p.isCanceled,
        isAdditional: p.isAdditional ? 'Sí' : 'No',
        receptionStatus: p.receptionStatus === 'received' || false,
        code: p.code,
        qrCode: p.qrCode
      }
    }
  },
  methods: {
    ...mapActions(['updateReceptionPackage']),

    updateReception (value) {
      const id = this.selectedPackage.id
      const data = { receptionStatus: value ? 'received' : 'not_received' }
      this.updateReceptionPackage({ id, data })
    },
    handleHide () {
      this.$router.push({ name: 'StoreWithdrawalsView' })
    }
  }

}
</script>

<style lang="scss" scoped>
.card-header {
  font-weight: 600;
  color: #20c997
}
</style>
