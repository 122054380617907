<template lang="pug">
  b-container#ScannerContainer
    scanner-in-store
</template>
<script>
import ScannerInStore from '../components/delivery/ScannerInStore.vue'
export default {
  components: {
    ScannerInStore
  }
}
</script>
<style lang="scss">
  #ScannerContainer{
    background-color: #191919;
    max-width: 600px;
    padding-top:15px;
    padding-bottom:15px;
  }
</style>
