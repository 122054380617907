<template lang="pug">
  b-container(fluid="lg")
    .tabla.sombra
      list-users
</template>

<script>
import ListUsers from '../components/user/ListUsers'

export default {
  components: {
    ListUsers
  }
}
</script>
