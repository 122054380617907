import axios from '../../plugins/axios'

export const listCommune = ({ commit }, { regionId } = {}) => {
  return axios.get(`/commune/region/${regionId}`)
    .then((response) => {
      commit('SET_COMMUNES', response.data)
      return response
    })
    .catch(err => err.response)
}

export const createCommune = (_, { data }) => {
  return axios.post('/commune', data)
    .then(response => response)
    .catch(err => err.response)
}

export const getCommune = ({ commit }, { communeId } = {}) => {
  return axios.get(`commune/${communeId}`)
    .then((response) => {
      commit('SET_COMMUNE', { commune: response.data })
      return response
    })
    .catch(err => err.response)
}

export const deleteCommune = (_, { id }) => {
  return axios.delete(`/commune/${id}`)
    .then(response => response)
    .catch(err => err.response)
}

export const updateCommune = (_, { id, data }) => {
  return axios.put(`/commune/${id}`, data)
    .then(response => response)
    .catch(err => err.response)
}

export const listActiveCommunes = () => {
  return axios.get('/active-communes')
    .then((response) => {
      return response.data
    })
    .catch(err => err.response)
}

export const listAllActiveCommunes = () => {
  return axios.get('/all-active-communes')
    .then((response) => {
      return response.data
    })
    .catch(err => err.response)
}

export const listAllCommunes = ({ commit }) => {
  return axios.get('/all-communes')
    .then((response) => {
      commit('SET_ALL_COMMUNES', response.data)
      return response.data
    })
    .catch(err => err.response)
}
