import axios from 'axios'
import settings from '../../settings'

const instance = axios.create({
  baseURL: settings.baseApi
})

instance.interceptors.request.use(config => {
  if (JSON.parse(localStorage.getItem('user'))) {
    config.headers.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('user')).token
  }
  return config
})

instance.interceptors.response.use((response) => {
  if (response.status >= 200 && response.status < 300) {
    response.ok = true
  }
  return response
},
error => Promise.reject(error))

export default instance
