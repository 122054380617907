<template lang="pug">
  .recover-password-view
    .bg-top
        img.recover-password-view-paper-plane(src="../assets/logos/avion_naranjo.png")
    b-row.h-100.m-2(align-h="center" align-v="center")
      b-col(sm="8" md="6" xl="4" xxl="3")
        LoginCardTemplate
          recover-password
    Footer
</template>
<script>
import RecoverPassword from '../components/auth/RecoverPassword'
import LoginCardTemplate from '../components/auth/LoginCardTemplate'
import Footer from '../components/auth/Footer'

export default {
  components: {
    RecoverPassword,
    LoginCardTemplate,
    Footer
  }
}
</script>

<style lang="scss">
.recover-password-view {
  position: relative;
  background-color: #F8F7F7;
  .bg-top {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 36%;
    background: var(--secondary);
    display: flex;
    justify-content: center;
  }

  &-paper-plane {
    position: absolute;
    top: calc(60% - 130px);
    margin: auto;
    width: auto;
    max-height: 80px;
  }

  height:100%;
  &-container {
    height: 100%;
  }
}
.login {
  margin: auto;
}
</style>
