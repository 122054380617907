const mutations = {
  'SET_STORES' (state, value) {
    state.stores = value
  },
  'SET_STORE' (state, value) {
    state.store = value
  },
  'SET_STATUS_STORES' (state, value) {
    state.statusStores = value
  }
}
export default mutations
