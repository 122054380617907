<template lang="pug">
#list-withdrawals.table-view
  .tabla.sombra
    b-row.mb-2(align-h="between" no-gutters style="gap: 10px")
      b-col
        h3.d-inline Historial de Retiros
      b-col(cols="12" md="auto" v-if="user.role === 'admin'")
        b-button(@click="$router.push('/escaner-en-tienda')" variant="status-approved" block) Escanear
      b-col(cols="12" md="auto" v-if="user.role === 'admin'")
        b-button(@click="exportPackagesnoDrivIn()" variant="status-approved" block) Exportar Retiros
      b-col(v-if="user.role === 'admin'" cols="12" md="auto")
        b-button(@click="exportPackages()" variant="status-approved" block) Exportar Envíos
      b-col(cols="12" md="auto" style="gap: 10px")
        b-button(v-b-modal.create-withdrawal-form-modal id='nuevo-retiro-store' variant="status-approved" block) Ingresar Nuevo
    b-form.mb-2(@submit.stop.prevent="fetchData()")
      b-row(style="gap: 10px;" no-gutters)
        b-col(v-if="user.role==='admin'" cols="12" md="3")
          b-form-input(list="list-stores" name="list-stores" v-model="backendFilters.search" placeholder="Seleccionar tienda" @change="handleSelectedStore" autocomplete="off")
          b-form-datalist#list-stores(:options="storesOptions")
        b-col(cols="12" md="3" lg="2" style="max-width: fit-content; width: 100%;")
          div(style="display: flex; min-width: 200px;")
            b-button(
              :style="styleDateBtn"
              style="border-radius: 5px 0px 0px 5px;"
              @click="toggleBtn('date')"
            ) Por fecha
            b-button(
              :style="styleRangeBtn"
              style="border-radius: 0px 5px 5px 0px;"
              @click="toggleBtn('range')"
            ) Por rango
        b-col(v-if="!backendFilters.isByRange" cols="12" md="3" lg="2")
          b-form-datepicker(
            start-weekday=1
            reset-button
            label-no-date-selected="sin fecha"
            hide-header
            today-button
            locale="es"
            label-today-button="Hoy"
            label-help="Usa el cursor para navegar por el calendario"
            v-model="backendFilters.retirementDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          )
        b-col(v-if="backendFilters.isByRange" cols="12" md="3" lg="2" style="max-width: fit-content")
          b-form-datepicker(
            label-no-date-selected="Desde..."
            v-model="backendFilters.startDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :min="backendFilters.minStartDate"
            :max="backendFilters.date"
            @input="setDateRange"
            reset-button
            style="width: fit-content"
          )
        b-col(v-if="backendFilters.isByRange" cols="12" md="3" lg="2" style="max-width: fit-content")
          b-form-datepicker(
            label-no-date-selected="Hasta..."
            v-model="backendFilters.endDate"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :min="backendFilters.startDate"
            :max="backendFilters.maxEndDate"
            reset-button
            style="width: fit-content"
          )
        b-col(cols="12" md="auto")
          b-button(type="submit" variant="outline-primary" block) Filtrar
        b-col(cols="12" md="auto")
          b-button(type="submit" variant="outline-primary" block @click="clearFilters()") Limpiar
    b-table(:items="withdrawals" :fields="fields" primary-key="id" responsive small :tbody-tr-class="disableRowClass" v-if="user.role !== 'admin'")
      template(v-slot:cell(disabled)="{ item }")
        check-disable-withdrawal(:withdrawal="item" @checked="onChecked")
      template(v-slot:cell(subtotals)="{ item }")
        div(v-for="t in item.packagesSizeAndRegionList")
          div {{t.code}}: {{t.count}}
        div Retiro: ${{item.retirementPrice.toLocaleString()}}
        div(v-if="item.volumeDiscount > 0") Desc. vol: ${{item.volumeDiscount.toLocaleString('es-CL')}}
      template(v-slot:cell(actions)="{ item }")
        b-button.text-decoration-none.button-link(v-b-modal.withdrawal-detail variant="link" @click="selectedWithdrawal=item" size="sm" style="color:var(--info)") Detalle
        b-button.text-decoration-none.button-link(v-b-modal.withdrawal-voucher variant="link" @click="selectedWithdrawal=item" size="sm" style="color:var(--info)") Comprobante
    // tabla administrador
    b-table(:items="groupWithdrawalsByDateAndStore" :fields="fieldsAdmin" primary-key="id" responsive small v-if="user.role === 'admin'")
      template(v-slot:cell(subtotals)="{ item }")
        div(v-for="t in item.packagesSizeAndRegionList")
          div {{t.code}}: {{t.count}}
        div Retiros: ${{item.retirementPrice.toLocaleString('es-CL')}}
      // boton mostrar detalle
      template(v-slot:cell(actions)="{ detailShowing, item }")
        b-button.text-decoration-none(size="sm" @click="toggleDetails(item)" variant="link" style="color:var(--secondary)") {{ item._showDetails ? 'Ocultar' : 'Mostrar'}}
      // tabla detalle
      template(v-slot:row-details="{ item }")
        b-table(:items="item.withdrawals" :fields="fields" :tbody-tr-class="disableRowClass" small responsive style="padding: 0px 20px;")
          template(v-slot:cell(subtotals)="{ item }")
            div(v-for="t in item.packagesSizeAndRegionList")
              div {{t.code}}: {{t.count}}
            div Retiro: ${{item.retirementPrice.toLocaleString('es-CL')}}
            div(v-if="item.volumeDiscount > 0") Desc. vol: ${{item.volumeDiscount.toLocaleString('es-CL')}}
          template(v-slot:cell(disabled)="{ item }")
            check-disable-withdrawal(:withdrawal="item" @checked="onChecked")
          template(v-slot:cell(actions)="{ item }")
            b-button.text-decoration-none.button-link(v-b-modal.withdrawal-detail variant="link" @click="selectedWithdrawal=item" size="sm" style="color:var(--info)") Detalle
            b-button.text-decoration-none.button-link(v-b-modal.withdrawal-voucher variant="link" @click="selectedWithdrawal=item" size="sm" style="color:var(--info)") Comprobante
    .d-flex.justify-content-center
      b-pagination.m-auto(
        v-model="backendFilters.page"
        :total-rows="countRows"
        :per-page="backendFilters.size"
        @change="backendFilters.page = $event, fetchData()"
        aria-controls="my-table"
      )
  b-modal#create-withdrawal-form-modal(ref="createWithdrawalFormModal" hide-footer size="xl" :click-to-close="true" @hide="hideWithdrawalModal()")
    withdrawal-form
  b-modal#withdrawal-voucher(hide-footer size="xl")
    template(v-slot:modal-title)
      h3 Comprobante
    iframe(v-if="selectedWithdrawal && selectedWithdrawal.voucher" :src="selectedWithdrawal.voucher" height="440px" width="100%")
  b-modal#withdrawal-detail(hide-footer size="xl" @hide="this.fetchData")
    template(v-slot:modal-title)
      h3 Detalle Envíos
    list-deliveries(@update="fetchData(), $bvModal.hide('withdrawal-detail')" :storeName="selectedWithdrawal.store.name" :storeData="selectedWithdrawal.store" :packages="selectedWithdrawal.packages" :retirementPrice="selectedWithdrawal.retirementPrice" :volumeDiscount="selectedWithdrawal.volumeDiscount" v-if="selectedWithdrawal")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import WithdrawalForm from './WithdrawalForm'
import CheckDisableWithdrawal from './CheckDisableWithdrawal'
import MakeToastMixin from '../mixins/MakeToastMixin.vue'
import ListDeliveries from './ListDeliveries.vue'
import SelectedStoreWithdrawalsAdmin from './SelectedStoreWithdrawalsAdmin'

export default {
  name: 'ListWithdrawals',
  mixins: [MakeToastMixin],
  components: {
    WithdrawalForm,
    CheckDisableWithdrawal,
    ListDeliveries,
    SelectedStoreWithdrawalsAdmin
  },
  data () {
    return {
      activeBtn: 'color: #fff; background-color: #3276b1; border-color: #285e8e; transform: translateY(0.8px);',
      inactiveBtn: 'color: #333; background-color: #fff; border-color: #ccc;',
      styleDateBtn: 'color: #fff; background-color: #3276b1; border-color: #285e8e; transform: translateY(0.8px);',
      styleRangeBtn: 'color: #333; background-color: #fff; border-color: #ccc;',
      selectedWithdrawal: null,
      currentItems: [],
      loading: false,
      stores: [],
      selectedId: null,
      backendFilters: {
        page: 1,
        size: 30,
        retirementDate: '',
        startDate: null,
        endDate: null,
        search: '',
        isByRange: false,
        minStartDate: null,
        maxStartDate: null,
        maxEndDate: null,
        date: new Date().toISOString().slice(0, 10),
        storeId: null
      },
      countRows: 0,
      fields: [
        { label: 'Encargado de Retiro', key: 'store.withdrawalAdgent' },
        { label: 'Código de Retiro', key: 'code' },
        {
          label: 'Teléfono de Retiro',
          key: 'pickupPhones',
          formatter: (value, key, item) => {
            const phones = [
              item.store.pickupPhone1,
              item.store.pickupPhone2
            ]
            return phones.filter(ph => !!ph).join(' - ')
          }
        },
        { label: 'Dirección de Retiro', key: 'store.withdrawalAddress' },
        { label: 'Comuna de Retiro', key: 'store.commune.name' },
        { label: 'Cant.', key: 'packagesAvailableCount' },
        { label: 'Subtotales', key: 'subtotals' },
        { label: 'Total', key: 'total', formatter: this.currencyFormatter },
        { label: 'Ver', key: 'actions' }
      ],
      fieldsAdmin: [
        { label: 'Tienda', key: 'storeName', sortable: true },
        { label: 'Código Tienda', key: 'storeInternalCode', sortable: false },
        { label: 'Fecha de Creación', key: 'createdAt', sortable: true },
        { label: 'Fecha de Retiro', key: 'retirementDate', sortable: true },
        { label: 'Subtotales', key: 'subtotals' },
        { label: 'Total', key: 'total', formatter: this.currencyFormatter },
        { label: 'Recepcionados', key: 'packagesReceivedCount' },
        // { label: 'Adicionales', key: 'packagesAdditionalCount' },
        { label: 'Retiros', key: 'actions' }
      ],
      fieldsStore: [
        { label: 'N°', key: 'id', sortable: true },
        {
          label: 'Fecha de Creación',
          key: 'createdAt',
          sortable: true,
          formatter: (value) => {
            return this.$moment(value).format('DD-MM-YYYY HH:mm')
          }
        },
        {
          label: 'Fecha de Retiro',
          key: 'retirementDate',
          sortable: true,
          formatter: (value) => {
            return this.$moment(value).format('DD-MM-YYYY HH:mm')
          }
        }
      ],
      additionalFields: [
        { label: 'Recepcionados', key: 'packagesReceivedCount' }
        // { label: 'Adicionales', key: 'packagesAdditionalCount' }
      ]
    }
  },
  async created () {
    this.fetchData()
    if (this.user.role === 'admin') {
      const lastField = this.fields.pop()
      this.fields.push(
        { label: 'Recepcionados', key: 'packagesReceivedCount' },
        // { label: 'Adicionales', key: 'packagesAdditionalCount' },
        { label: 'Inhabilitado', key: 'disabled' }
      )
      this.fields.push(lastField)
      this.stores = await this.listAllStoreName()
    } else {
      this.fields = this.fieldsStore.concat(this.fields)
    }
  },
  computed: {
    ...mapGetters(['withdrawals', 'withdrawal', 'user', 'allCommunes']),
    storesOptions () {
      return this.stores.map(store => {
        return {
          // ‎ Son caracteres invisible para el usuario, pero que permite mejorar el filtrado de las tiendas y su internalCode
          value: store.internalCode ? `${store.name} ‎-‎ ${store.internalCode}` : `${store.name}`,
          text: `${store.name} - ${store.name}`
        }
      })
    },
    groupWithdrawalsByDateAndStore () {
      const grupedWithdrawals = this.withdrawals.reduce((accum, w) => {
        const date = this.$moment(w.retirementDate).format('DD/MM/YYYY')
        const groupId = `${date}-${w.storeId}`
        if (!accum[groupId]) {
          accum[groupId] = []
        }
        accum[groupId].push(w)
        return accum
      }, {})
      // Calcula los campos para la tabla principal
      return Object.keys(grupedWithdrawals).map(key => {
        const withdrawals = grupedWithdrawals[key] // Array de retiros para la subtabla
        const storeName = withdrawals[0].store.name
        const date = this.$moment(withdrawals[0].retirementDate).format('DD/MM/YYYY')
        const createdAt = this.$moment(withdrawals[0].createdAt).format('DD/MM/YYYY HH:mm')
        const total = withdrawals.reduce((accum, w) => accum + w.total, 0)
        const packagesSizeAndRegionList = withdrawals.reduce((accum, w) => {
          if (w.packagesSizeAndRegionList) {
            w.packagesSizeAndRegionList.forEach(p => {
              const index = accum.findIndex(a => a.code === p.code)
              if (index === -1) {
                accum.push({ ...p })
              } else {
                accum[index].count += p.count
              }
            })
          }
          return accum
        }, [])
        const retirementPrice = withdrawals.reduce((accum, w) => accum + w.retirementPrice, 0)
        const volumeDiscount = withdrawals.reduce((accum, w) => accum + w.volumeDiscount, 0)
        const totalReceived = 'packagesReceivedCount'
        const totalPackages = withdrawals.reduce((accum, w) => accum + w.packagesAvailableCount, 0)
        const packagesAdditionalCount = withdrawals.reduce((accum, w) => accum + w.packagesAdditionalCount, 0)
        const packagesReceivedCount = `${withdrawals.reduce((accum, w) => accum + Number(w[totalReceived].split('/')[0]), 0)}/${totalPackages}`

        return {
          volumeDiscount,
          storeName,
          createdAt,
          retirementDate: date,
          total,
          withdrawals,
          packagesSizeAndRegionList,
          retirementPrice,
          storeInternalCode: withdrawals[0].store.internalCode,
          packagesReceivedCount,
          packagesAdditionalCount
        }
      })
    }
  },
  methods: {
    ...mapActions(['listWithdrawals', 'listAllCommunes', 'exportPackagesWithdrawals', 'exportPackagesWithdrawalsNoDrivIn', 'listAllStoreName', 'getRegion']),
    toggleDetails (row) {
      if (row._showDetails) {
        this.$set(row, '_showDetails', false)
      } else {
        this.currentItems.forEach(item => {
          this.$set(item, '_showDetails', false)
        })
        this.$nextTick(() => {
          this.$set(row, '_showDetails', true)
        })
      }
    },
    currencyFormatter (value) {
      return `$${value.toLocaleString('es-CL')}`
    },
    clearFilters () {
      this.backendFilters.retirementDate = ''
      this.backendFilters.search = ''
      this.backendFilters.startDate = ''
      this.backendFilters.endDate = ''
      this.backendFilters.minStartDate = ''
      this.backendFilters.maxStartDate = ''
      this.backendFilters.maxEndDate = ''
    },
    hideWithdrawalModal () {
      this.fetchData()
    },
    onChecked () {
      this.fetchData()
    },
    handleSelectedStore (value) {
      // ‎ Son caracteres invisible para el usuario, pero que permite mejorar el filtrado de las tiendas y su internalCode
      if (value.includes('‎-‎')) {
        const storeName = value.split(' ‎-‎ ')[0]
        const internalCode = value.split(' ‎-‎ ')[1]
        // console.log(storeName, 'StoreName', internalCode, 'Internal Code')
        const storeSelected = this.stores.filter((s) => s.internalCode === internalCode && s.name === storeName)

        if (storeSelected.length > 0) {
          this.backendFilters.storeId = storeSelected[0].id
        }
        this.backendFilters.search = value.split(' ‎-‎ ')[0]
        this.backendFilters.internalCode = value.split(' ‎-‎ ')[1]
      } else {
        delete this.backendFilters.internalCode
        this.backendFilters.storeId = null
      }
    },
    async fetchData () {
      this.validateDates()

      this.loading = true
      const response = await this.listWithdrawals({ params: { ...this.backendFilters } })
      this.loading = false
      if (response.ok) {
        this.countRows = response.data.count
      }
    },
    async exportPackages () {
      if (!this.backendFilters.isByRange ? this.backendFilters.retirementDate === '' : !this.backendFilters.startDate || !this.backendFilters.endDate) {
        this.makeDangerToast('Debes seleccionar un fecha', 'Error')
      } else {
        await this.exportPackagesWithdrawals({ params: { retirementDate: this.backendFilters.retirementDate, storeName: this.backendFilters.search, isByRange: this.backendFilters.isByRange, startDate: this.backendFilters.startDate, endDate: this.backendFilters.endDate, storeId: this.backendFilters.storeId } })
      }
    },
    async exportPackagesnoDrivIn () {
      if (!this.backendFilters.isByRange ? this.backendFilters.retirementDate === '' : !this.backendFilters.startDate || !this.backendFilters.endDate) {
        this.makeDangerToast('Debes seleccionar un fecha', 'Error')
      } else {
        await this.exportPackagesWithdrawalsNoDrivIn({ params: { retirementDate: this.backendFilters.retirementDate, storeName: this.backendFilters.search, isByRange: this.backendFilters.isByRange, startDate: this.backendFilters.startDate, endDate: this.backendFilters.endDate, storeId: this.backendFilters.storeId } })
      }
    },
    disableRowClass (item, type) {
      if (item && type === 'row') {
        return item.disabled ? 'text-disable' : null
      }
    },
    handleKeyUp (event) {
      // 27 = tecla 'esc'
      if (event.keyCode === 27) {
        this.clearFilters()
        this.fetchData()
      }
    },
    toggleRange () {
      this.backendFilters.isByRange = !this.backendFilters.isByRange
      this.backendFilters.retirementDate = ''

      this.backendFilters.startDate = ''
      this.backendFilters.endDate = ''
    },
    setDateRange (value) {
      this.backendFilters.endDate = null
      if (!value) return

      const date = new Date(value)
      const maxDate = new Date(date.setMonth(date.getMonth() + 1))

      this.backendFilters.minEndDate = value
      this.backendFilters.maxEndDate = maxDate.toISOString().slice(0, 10)
    },
    validateDates () {
      if (this.backendFilters.isByRange) {
        if (this.backendFilters.startDate && !this.backendFilters.endDate) {
          this.backendFilters.endDate = this.backendFilters.maxEndDate
        } else if (this.backendFilters.endDate && !this.backendFilters.startDate) {
          // this.backendFilters.startDate = this.backendFilters.endDate
          const date = new Date(this.backendFilters.endDate)
          const minDate = new Date(date.setMonth(date.getMonth() - 1))
          this.backendFilters.startDate = minDate.toISOString().slice(0, 10)
        }
      }
    },
    toggleBtn (btn) {
      if (btn === 'date') {
        this.styleDateBtn = this.activeBtn
        this.styleRangeBtn = this.inactiveBtn
        if (this.backendFilters.isByRange) {
          this.toggleRange()
        }
      } else {
        this.styleRangeBtn = this.activeBtn
        this.styleDateBtn = this.inactiveBtn
        if (!this.backendFilters.isByRange) {
          this.toggleRange()
        }
      }
    }
  },
  mounted () {
    window.addEventListener('keyup', this.handleKeyUp)
  },
  destroyed () {
    window.removeEventListener('keyup', this.handleKeyUp)
  },
  watch: {
    'backendFilters.search': {
      handler (value) {
        if (!value) {
          this.backendFilters.storeId = null
          delete this.backendFilters.internalCode
        }
      },
      deep: true
    }
  }

}
</script>
<style lang="scss" scoped>
#list-withdrawals {
  .modal-header {
    border-bottom: none !important;
    color: black;
    font-weight: 900;
  }
  .table-sm th, .table-sm td {
    padding: 0px;
  }
}
</style>
<style>
.hidden_header {
   display: none;
}
.close {
  z-index: 10;
}
</style>
