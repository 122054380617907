<template>
  <div style="width: 100%; height: 100vh;">
    <div v-if="pdf" class="d-flex p-2" style="border-bottom: 1px solid #ccc; justify-content: end;">
      <b-btn :href="pdf" :download="pdfName" target="_blank" size="sm">Descargar PDF</b-btn>
    </div>
    <embed v-if="pdf" type="application/pdf" style="width: 100%; height: 100%;" :src="pdf" />
  </div>
</template>

<script>
import Handlebars from 'handlebars'
import { mapActions } from 'vuex'
import { jsPDF as JsPDF } from 'jspdf'

export default {
  data () {
    return {
      pdf: null,
      pdfName: 'comprobante.pdf'
    }
  },
  methods: {
    ...mapActions(['generatePDF']),

    async showPDF () {
      const id = this.$route.params.id

      const response = await this.generatePDF({ id })
      if (response.ok) {
        const responseHtml = await fetch('/templatePDF.html')
        const template = await responseHtml.text()
        const compiledTemplate = Handlebars.compile(template)
        const pdfContainer = document.createElement('div')

        this.pdfName = `${response.data.direccion}, ${response.data.comuna} (${response.data.numeroSeguimiento}).pdf`

        pdfContainer.innerHTML = compiledTemplate(response.data)

        const doc = new JsPDF({
          unit: 'px',
          format: 'a4',
          orientation: 'portrait',
          hotfixes: ['px_scaling']
        })
        doc.html(pdfContainer, {
          callback: (doc) => {
            const blob = doc.output('blob')
            const url = URL.createObjectURL(blob)
            this.pdf = url
          }
        })
      } else {
        this.$bvToast.toast('No se pudo generar el PDF', {
          variant: 'danger',
          solid: true,
          title: 'PDF'
        })
      }
    },
    downloadPDF () {
      const link = document.createElement('a')
      link.href = this.pdf
      link.download = this.pdfName
      link.target = '_blank'
      link.click()
    }
  },
  created () {
    this.showPDF()
  }
}
</script>
