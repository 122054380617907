<template lang="pug">
#Delivery-tracking
  b-row.title.pt-4
    h5.tittle.text-uppercase Consultar Seguimiento
  b-form(@submit.stop.prevent="onSubmit")
    b-row.search-bar
      b-col
        .box-form
          b-form-group(
            label-for="search-input"
          )
            .input-title
            b-form-input#search-input.input(
              v-model="form.search"
              placeholder="Ingrese código de pedido"
              maxlength="150"
              :formatter="formatSearch"
            )
      b-col
        b-button.btn-search(type="submit" :disabled="loading") Buscar
  b-row.row-pdf
    .pdf-container
      vue-pdf-app(v-if="arrayBuff" style="max-height: 120vh; width: 90vw;" :pdf="arrayBuff" file-name="comprobante.pdf")
      span.not-found(v-if="notFound") {{ errorMessage || 'No se encontró el envío' }}
      span.loading-text(v-if="loading") {{ 'Buscando...' }}
</template>

<script>
import VuePdfApp from '@vurses/vue-pdf-app'
import '@vurses/vue-pdf-app/dist/icons/main.css'
import { mapActions } from 'vuex/dist/vuex.common.js'
import Handlebars from 'handlebars'
import { jsPDF as JsPDF } from 'jspdf'

export default {
  components: {
    VuePdfApp
  },
  data () {
    return {
      loading: false,
      notFound: false,
      arrayBuff: null,
      errorMessage: '',
      form: {
        search: null
      }
    }
  },
  methods: {
    ...mapActions(['deliveryTracking']),
    formatSearch (value) {
      if (this.notFound) this.notFound = false
      return value.replace(/\s+/g, '').toUpperCase()
    },
    async generatePDF (data) {
      const responseHtml = await fetch('/templatePDF.html')
      const template = await responseHtml.text()
      const compiledTemplate = Handlebars.compile(template)
      const pdfContainer = document.createElement('div')

      pdfContainer.innerHTML = compiledTemplate(data)

      // Devuelve una nueva promesa correctamente definida
      return new Promise((resolve, reject) => {
        try {
          const doc = new JsPDF({
            unit: 'px',
            format: 'a4',
            orientation: 'portrait',
            hotfixes: ['px_scaling']
          })
          doc.html(pdfContainer, {
            callback: (doc) => {
              try {
                const blob = doc.output('blob')
                const url = URL.createObjectURL(blob)
                resolve(url) // Resuelve la promesa con el URL
              } catch (error) {
                reject(error) // Rechaza la promesa en caso de error
              }
            }
          })
        } catch (error) {
          reject(error) // Rechaza la promesa si algo falla antes
        }
      })
    },
    async onSubmit (item) {
      this.loading = true
      this.notFound = false
      this.errorMessage = ''
      this.arrayBuff = null
      // const id = item.id

      const response = await this.deliveryTracking({ code: this.form.search })
      if (response.status === 200) {
        const responseData = response.data

        if (!responseData.driverId && responseData.pdfUrl) {
          fetch(responseData.pdfUrl)
            .then(response => response.blob())
            .then(blob => {
              this.$bvToast.hide()
              const reader = new FileReader()
              reader.onload = () => {
                const arrayBuffer = reader.result
                console.log(arrayBuffer)
                this.arrayBuff = arrayBuffer
              }
              reader.readAsArrayBuffer(blob)
            })
            .catch(error => console.error('Error:', error))
            .finally(() => {
              this.loading = false
            })
          return
        }

        const pdfUrl = await this.generatePDF(responseData)

        fetch(pdfUrl)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader()
            reader.onload = () => {
              const arrayBuffer = reader.result
              console.log(arrayBuffer)
              this.arrayBuff = arrayBuffer
            }
            reader.readAsArrayBuffer(blob)
          })
          .catch(error => console.error('Error:', error))
      } else if (response.status >= 400) {
        this.notFound = true
        this.arrayBuff = null
        this.errorMessage = response.data.message || null
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
#Delivery-tracking {
  padding-left: 1rem;
}
.search-input {
  width: 70%;
}
.tittle {
  font-size: 18px;
  color: #616b7f;
  font-weight: 700;
  font-family: Ubuntu, sans-serif;
  line-height: 1.1;
}
.pdf-container {
  width: 90vw;
  height: 90vh !important;
}
.row-pdf {
  max-width: 90vw;
  max-height: 90vh;
  margin-bottom: 2rem;
}
.not-found {
  padding: 1.5rem;
  color: #FF4500;
}
.loading-text {
  padding: 1.5rem;
  color: grey;
}
</style>
