<template lang="pug">
  b-container(fluid="lg")
    .tabla.sombra
      list-groups
</template>

<script>
import ListGroups from '../components/group/ListGroups'

export default {
  components: {
    ListGroups
  }
}
</script>
