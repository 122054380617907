import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const RegionStore = {
  state: {
    regionStores: [],
    regionStore: {}
  },
  getters,
  mutations,
  actions
}

export default RegionStore
