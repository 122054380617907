import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const PackageStore = {
  state: {
    selectedPackage: {}
  },
  getters,
  mutations,
  actions
}

export default PackageStore
