<template lang="pug">
  div
    b-row.my-4(align-h="between")
      b-col.mb-2
        h3.d-inline Mensajes
      b-col(md="auto" cols="12")
        b-button(v-b-modal.create-message-form-modal block) Ingresar Nuevo
    b-table(:fields="fields" :items="messageData" responsive)
      template(#cell(actions)="{item}")
        .material-icons-outlined.btn-icon.mr-2.mb-1(@click="openEditModal(item)") edit
        .material-icons-outlined.btn-icon.mr-2.mb-1.boton-eliminar(@click="onDeleteMessage(item)") delete_outline
      template(#cell(seeGroups)="{item}")
        b-button(@click="openSeeMessagesModal(item)" variant="link") Ver
      template(#cell(is_active)="{item}")
        div {{item.is_active ? 'Si' : 'No'}}
    b-modal#see-groups-message-modal(ref="seeStoresMessageModal" hide-footer title="Grupos")
      div(v-if="selectedMessage")
        div(v-for="group in selectedMessage.groups") {{group.name}}
    b-modal#create-message-form-modal(ref="createMessageFormModal" hide-footer title="Creando Mensaje")
      message-form(@done="onDoneMessageModal")
    b-modal(ref="updateMessageFormModal" hide-footer title="Editando Mensaje")
      message-form(v-if="selectedMessage" @done="onDoneMessageModal" :message="selectedMessage" :editMode="true")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import MessageForm from './MessageForm'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  components: {
    MessageForm
  },
  data () {
    return {
      selectedMessage: null,
      fields: [

        { label: 'Título', key: 'subject' },
        { label: 'Contenido', key: 'text' },
        { label: 'Grupos', key: 'seeGroups' },
        { label: 'Activo', key: 'is_active' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listMessage', 'deleteMessage']),
    onDoneMessageModal () {
      this.fetchData()
      this.$refs.createMessageFormModal.hide()
      this.$refs.updateMessageFormModal.hide()
      this.$emit('done')
    },
    openEditModal (message) {
      this.selectedMessage = message
      this.$refs.updateMessageFormModal.show()
    },
    openSeeMessagesModal (message) {
      this.selectedMessage = message
      this.$refs.seeStoresMessageModal.show()
    },
    async fetchData () {
      await this.listMessage()
    },
    async onDeleteMessage (message) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar ${message.subject}?`)
      if (!ok) return

      const response = await this.deleteMessage({ messageId: message.id })
      if (response.status === 200) {
        this.makeSuccessToast('El grupo se eliminó correctamente')
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar el grupo')
      }
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters(['messages']),
    messageData () {
      return this.messages.reduce((acum, message) => acum.concat({
        id: message.id,
        subject: message.subject,
        groups: message.groups,
        text: message.text,
        is_active: message.is_active,
        is_universal: message.is_universal
      }), [])
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
