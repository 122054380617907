<template lang="pug">
  b-form(@submit.stop.prevent="onSubmit")
    b-row
      b-col
        .box-form
          .material-icons.icon-input location_on
          b-form-group.input-title(
            label-for="name-input"
            label="Nombre"
            invalid-feedback="Este campo es requerido"
          )
            b-form-input#name-input.input(
              v-model="form.name"
              maxlength="256"
              :state="$v.$dirty ? !$v.form.name.$invalid : null"
            )
        hr.linea
        .box-form
          .material-icons.icon-input location_on
          b-form-group.input-title(
            label-for="sigla-input"
            label="Sigla"
            invalid-feedback="Este campo es requerido"
          )
            b-form-input#sigla-input.input(
              v-model="form.sigla"
              maxlength="256"
              :state="$v.$dirty ? !$v.form.sigla.$invalid : null"
            )
        hr.linea
        .box-form
          .material-icons.icon-input numbers
          b-form-group.input-title(
            label="Orden"
            label-for="order-input"
          )
            b-form-input#order-input.input(
              type="number"
              v-model="form.order"
            )
        hr.linea
        .box-form
          .material-icons.icon-input location_city
          b-form-group.input-title(
            label="Precio de retiro urbano"
            label-for="urban-price-input"
            invalid-feedback="Máximo 10 números"
          )
            b-form-input#urban-price-input.input(
              v-model="form.urbanRetirementPrice"
              type="number"
              :state="$v.$dirty ? !$v.form.urbanRetirementPrice.$invalid : null"
            )
        hr.linea
      b-col
        .box-form
          .material-icons.icon-input filter_hdr
          b-form-group.input-title(
            label="Precio de retiro rural"
            label-for="rural-price-input"
            invalid-feedback="Máximo 10 números"
          )
            b-form-input#rural-price-input.input(
              v-model="form.ruralRetirementPrice"
              type="number"
              :state="$v.$dirty ? !$v.form.ruralRetirementPrice.$invalid : null"
            )
        hr.linea
        .box-form
          .material-icons-outlined.icon-input inventory_2
          b-form-group.input-title(
            label="Precio envío pequeño"
            label-for="small-price-input"
            invalid-feedback="Máximo 10 números"
          )
            b-form-input#small-price-input.input(
              v-model="form.smallShippingPrice"
              type="number"
              :state="$v.$dirty ? !$v.form.smallShippingPrice.$invalid : null"
            )
        hr.linea
        .box-form
          .material-icons-outlined.icon-input inventory_2
          b-form-group.input-title(
            label="Precio envío mediano"
            label-for="medium-price-input"
            invalid-feedback="Máximo 10 números"
          )
            b-form-input#medium-price-input.input(
              v-model="form.mediumShippingPrice"
              type="number"
              :state="$v.$dirty ? !$v.form.mediumShippingPrice.$invalid : null"
            )
        hr.linea
        .box-form
          .material-icons.icon-input
          b-form-group.input-title(
            label="Disponible para retiro"
            label-for="available-pickup-input"
          )
            b-form-checkbox#available-pickup-input.input(
              v-model="form.availableForPickup"
            )
    .text-right
      b-button(type="submit" variant="status-approved") Guardar
</template>

<script>
import { required, maxLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  props: {
    region: {
      type: Object,
      default: null
    },
    editMode: Boolean
  },
  data () {
    return {
      form: {
        name: this.editMode ? this.region.name : '',
        sigla: this.editMode ? this.region.sigla : '',
        order: this.editMode ? this.region.order : 0,
        availableForPickup: this.editMode ? this.region.availableForPickup : true,
        urbanRetirementPrice: this.editMode ? this.region.urbanRetirementPrice : null,
        ruralRetirementPrice: this.editMode ? this.region.ruralRetirementPrice : null,
        smallShippingPrice: this.editMode ? this.region.smallShippingPrice : null,
        mediumShippingPrice: this.editMode ? this.region.mediumShippingPrice : null
      }
    }
  },
  validations () {
    const form = {
      name: { required },
      sigla: { required },
      urbanRetirementPrice: { maxLength: maxLength(10) },
      ruralRetirementPrice: { maxLength: maxLength(10) },
      smallShippingPrice: { maxLength: maxLength(10) },
      mediumShippingPrice: { maxLength: maxLength(10) }
    }
    return { form }
  },
  async created () {
    if (this.$route.params.regionId) {
      await this.getRegion({ regionId: this.$route.params.regionId })
      this.form = this.region
    }
  },
  methods: {
    ...mapActions(['createRegion', 'updateRegion', 'getRegion']),
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          this.onUpdateRegion()
        } else {
          this.onCreateRegion()
        }
      }
    },
    async onCreateRegion () {
      this.form.sigla = this.form.sigla.toUpperCase()
      const cleanForm = Object.entries(this.form).reduce((acum, [key, value]) => (value === null ? acum : (acum[key] = value, acum)), {}) // eslint-disable-line
      const response = await this.createRegion({ data: cleanForm })
      if (response.status === 201) {
        this.makeSuccessToast('La región se creó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear la región')
      }
    },
    async onUpdateRegion () {
      this.form.sigla = this.form.sigla.toUpperCase()
      const response = await this.updateRegion({ id: this.region.id, data: this.form })
      if (response.status === 200) {
        this.makeSuccessToast('La región se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar la región')
      }
    }
  }
}
</script>
<style scoped>
.box-form {
  align-items: center;
}
</style>
