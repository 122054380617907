<template lang="pug">
  .status-badge(:style="style")
    span {{statusName}}
</template>

<script>
import deliveryStatus from '../../common/deliveryStatus.json'
export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      statuses: deliveryStatus
    }
  },
  computed: {
    statusName () {
      return this.statuses[this.status].name ? this.statuses[this.status].name : this.status
    },
    style () {
      const backgroundColor = this.statuses[this.status].bgColor ? this.statuses[this.status].bgColor : 'white'
      const color = this.statuses[this.status].textColor ? this.statuses[this.status].textColor : 'white'
      return { backgroundColor, color }
    }
  }
}
</script>

<style lang="scss" scoped>
.status-badge {
  text-align: center;
  width: 110px;
  height: 25px;
  border-radius: 10px;
  font-size: 15px/19px;
  font-weight: 600;
  padding-top: 1px;
}
</style>
