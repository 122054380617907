<template lang="pug">
  b-container(fluid="lg")
    .box.sombra(v-if="store")
      h3.text-break(v-if="store") Tienda: {{store.name}}
      StoreForm
      list-user(v-if="store" :storeId="store.id" :userList="users" @done="listUser({ params: { storeId: $route.params.storeId } })" style="margin-top:50px")
</template>

<script>
import StoreForm from '../components/store/StoreForm'
import ListStoreUsers from '../components/store/ListStoreUsers'
import ListUser from '../components/user/ListUsers'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ListStoreUsers,
    ListUser,
    StoreForm
  },
  methods: {
    ...mapActions(['getStore', 'listUser'])
  },
  computed: {
    ...mapGetters(['store', 'users'])
  },
  created () {
    this.getStore({ storeId: this.$route.params.storeId })
  }
}
</script>
