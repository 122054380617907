<script>
export default {
  methods: {
    makeToast (msg, title, variant) {
      this.$root.$bvToast.toast(msg || '', {
        title: title || '',
        variant: variant || 'primary'
      })
    },
    makeDangerToast (msg, title) {
      this.makeToast(msg, title || 'Error!', 'danger')
    },
    makeSuccessToast (msg, title) {
      this.makeToast(msg, title || 'Éxito!', 'success')
    }
  }
}
</script>
