<template lang="pug">
  #store-form
    b-form(@submit.stop.prevent="onSubmit")
      h5 Datos del Retiro
      b-row#retirement-data.fila
        b-col
          .box-form
            .material-icons-outlined.icon-input store
            b-form-group.input-title(
              label-for="store-name-input"
              label="Nombre de la tienda"
              invalid-feedback="Este campo es requerido"
            )
              b-form-input#store-name-input.input(
                v-model="form.storeName"
                maxlength="256"
                placeholder="Ingresa el nombre de la tienda"
                :state="$v.$dirty ? !$v.form.storeName.$invalid : null"
              )
          hr.linea
          .box-form
            .material-icons.icon-input account_box
            b-form-group.input-title(
              label="Nombre del Encargado"
              label-for="withdrawal-adgent-input"
              invalid-feedback="Este campo es requerido"
            )
              b-form-input#withdrawal-adgent-input.input(
                v-model="form.withdrawalAdgent"
                maxlength="256"
                placeholder="Ingresa en Nombre del Encargado"
                :state="$v.$dirty ? !$v.form.withdrawalAdgent.$invalid : null"
              )
          hr.linea
          .box-form
            .material-icons.icon-input local_offer
            b-form-group.input-title(
              label="Rubro"
              label-for="line-input"
              invalid-feedback="Este campo es requerido"
            )
              b-form-input#line-input.input(
                v-model="form.line"
                placeholder="Ingresa el Rubro"
                :state="$v.$dirty ? !$v.form.line.$invalid : null"
              )
          hr.linea
        b-col
          .box-form
            .material-icons.icon-input fork_left
            b-form-group.input-title(
              label="Dirección de Retiro"
              label-for="withdrawal-address-input"
              invalid-feedback="Este campo es requerido"
            )
              b-form-input#withdrawal-address-input.input(
                v-model="form.withdrawalAddress"
                placeholder="Ingresa la Calle y el Número"
                :state="$v.$dirty ? !$v.form.withdrawalAddress.$invalid : null"
              )
          hr.linea
          .box-form
            .material-icons-outlined.icon-input location_on
            b-form-group.input-title(
              label="Comuna"
              label-for="withdrawal-commune-input"
              invalid-feedback="Este campo es requerido"
            )
              b-form-select#withdrawal-commune-input.input(
                v-model="form.withdrawalCommuneId"
                :options="communeOptions"
                placeholder="Ingresa la Comuna"
                :state="$v.$dirty ? !$v.form.withdrawalCommuneId.$invalid : null"
              )
          hr.linea
          b-row#billing-data.fila
            b-col
              .box-form
                .material-icons-outlined.icon-input phone
                b-form-group.input-title(
                  label="Teléfono"
                  label-for="pickup-phone1-input"
                  invalid-feedback="Este campo es requerido"
                )
                  b-form-input#pickup-phone-1-input.input(
                    v-model="form.pickupPhone1"
                    placeholder="Ingresa Teléfono"
                    :state="$v.$dirty ? !$v.form.pickupPhone1.$invalid : null"
                  )
              hr.linea
            b-col
              .box-form
                .material-icons-outlined.icon-input phone
                b-form-group.input-title(
                  label="Teléfono (Opcional)"
                  label-for="pickup-phone1-input"
                )
                  b-form-input#pickup-phone-1-input.input(
                    v-model="form.pickupPhone2"
                    placeholder="Ingresa Teléfono"
                  )
              hr.linea
      b-row#billing-data.fila
        b-col(cols="12" sm="6")
          .box-form
            .material-icons-outlined.icon-input key
            b-form-group.input-title(
              label-for="store-name-input"
              label="Código de la tienda"
              :invalid-feedback="backendErrors.internalCode"
            )
              b-form-input#store-name-input.input(
                v-model="form.internalCode"
                maxlength="256"
                :state="backendErrors.internalCode ? false : null"
                placeholder="Ingresa el código de la tienda"
                :disabled="!checkRole(ADMIN_ROLE)"
                @input="backendErrors.internalCode = null"
              )
          hr.linea
      b-form-checkbox.mt-2.mb-(v-model="requires") Requiere Facturación
      b-form-checkbox.mt-2.mb-(v-model="form.noRequiresMin" v-if="user.role === 'admin'") No Requiere de mínimo 3 envíos
      .box-form
        b-form-checkbox#checkbox-1(
          v-if="role === 'admin'"
          v-model="form.noRequiresVoucher"
          value= 'true'
          unchecked-value= 'false'
        ) No Requiere Comprobante de Pago
      div(v-if="requires")
        h5.mt-5.mb-4 Datos de Facturación
        b-row.fila
          b-col
            .box-form
              .material-icons.icon-input store
              b-form-group.input-title(
                label-for="company-name-input"
                label="Razón Social"
              )
                b-form-input#company-name-input.input(
                  v-model="form.companyName"
                  maxlength="256"
                  placeholder="Ingresa la Razón Social"
                )
            hr.linea
            .box-form
              .material-icons.icon-input account_box
              b-form-group.input-title(
                label="RUT de Facturación"
                label-for="rut-input"
              )
                b-form-input#rut-input.input(
                  v-model="form.rut"
                  placeholder="Ingresa el RUT de Facturación"
                )
            hr.linea
            .box-form
              .material-icons.icon-input local_offer
              b-form-group.input-title(
                label="Giro de la Tienda"
                label-for="business-line-input"
              )
                b-form-input#business-line-input.input(
                  v-model="form.businessLine"
                  placeholder="Ingresa el Giro"
                )
            hr.linea
          b-col
            .box-form
              .material-icons-outlined.icon-input store
              b-form-group.input-title(
                label="Dirección de Facturación"
                label-for="billing-address-input"
              )
                b-form-input#billing-address-input.input(
                  v-model="form.billingAddress"
                  placeholder="Ingresa la Calle y Número"
                )
            hr.linea
            .box-form
              .material-icons-outlined.icon-input location_on
              b-form-group.input-title(
                label="Comuna de Facturación"
                label-for="billing-commune-input"
              )
                b-form-input#billing-commune-input.input(
                  v-model="form.billingCommune"
                  placeholder="Ingresa la Comuna"
                )
            hr.linea
            .box-form
              .material-icons-outlined.icon-input phone
              b-form-group.input-title(
                label="Teléfono de Facturación"
                label-for="billing-phone-input"
              )
                b-form-input#billing-phone-input.input(
                  v-model="form.billingPhone"
                  placeholder="Ingresa Teléfono"
                )
            hr.linea
      .text-right
        b-button(type="submit" variant="status-approved") Guardar
    .configuration-store-label.mb-4
      h3.d-inline.mb-3 Configuración Etiqueta
        b-row#retirement-data.fila
          b-col.mt-3
            .box-form
              .material-icons-outlined.icon-input edit
              b-form-group.input-title(
                label-for="store-field-one-input"
                label="Campo personalizable 1"
              )
                b-form-input#store-field-one-input.input(
                  v-model="form.customizableFieldOne"
                  maxlength="256"
                  placeholder="Texto personalizado"
                  @change="showField"
                )
            hr.linea
            .box-form
              .material-icons.icon-input edit
              b-form-group.input-title(
                label="Campo personalizable 2"
                label-for="store-field-two-input"
              )
                b-form-input#store-field-two-input.input(
                  v-model="form.customizableFieldTwo"
                  maxlength="256"
                  placeholder="Texto personalizado"
                  @change="showField"
                )
            hr.linea
            .box-form
              .material-icons.icon-input account_circle
              b-form-group.input-title(
                label="Logotipo"
                label-for="store-logo-input"
              )
                b-form-file(
                  v-model="form.logo"
                  :placeholder="form.logoUrl ? 'Haz click aquí para subir un nuevo logo' : 'Haz click aquí para subir tu logo'"
                  accept=".jpg, .png"
                  style="background-color: #FEFFEE;"
                  @input="setFile"
                )
            hr.linea
          b-col(md="6" cols="12")
            .text-center
              h5 Vista previa
              PackageLabelTemplate(:context="packageLabelContext" style="border: solid #DDD 1px; border-radius: 5px; display:inline-block;")
    div(v-if="user.role === 'admin' && store")
      h3.d-inline Retiros
      select-options-withdrawals.mt-3(:store="store")
    b-form(
      @submit.stop.prevent="onSubmitPrice"
      v-if="role === 'admin'"
      )
      h5 Precios de Tienda
      .box-form
        .material-icons-outlined.icon-input location_on
        b-form-group.input-title(
          label="Region"
          label-for="withdrawal-commune-input"
          invalid-feedback="Este campo es requerido"
        )
          b-form-select#withdrawal-commune-input.input(
            placeholder="Ingresa una Region"
            v-model="formPrecios.idRegion"
            :options="regionOptions"
            @change= "SelectCommunePrice()"
            :state="null"
          )
      hr.linea
      b-row#retirement-data.fila
        b-col
          .box-form
            .material-icons.icon-input location_city
            b-form-group.input-title(
              label="Precio de retiro urbano"
              label-for="urban-price-input"
              invalid-feedback="Máximo 10 números"
            )
              b-form-input#urban-price-input.input(
                v-model="formPrecios.urbanRetirementPrice"
                type="number"
              )
          hr.linea
          .box-form
            .material-icons.icon-input filter_hdr
            b-form-group.input-title(
              label="Precio de retiro rural"
              label-for="rural-price-input"
              invalid-feedback="Máximo 10 números"
            )
              b-form-input#rural-price-input.input(
                v-model="formPrecios.ruralRetirementPrice"
                type="number"
              )
          hr.linea
        b-col
          .box-form
            .material-icons.icon-input inventory_2
            b-form-group.input-title(
              label="Precio envío pequeño"
              label-for="small-price-input"
              invalid-feedback="Máximo 10 números"
            )
              b-form-input#small-price-input.input(
                v-model="formPrecios.smallShippingPrice"
                type="number"
              )
          hr.linea
          .box-form
            .material-icons.icon-input inventory_2
            b-form-group.input-title(
              label="Precio envío mediano"
              label-for="medium-price-input"
              invalid-feedback="Máximo 10 números"
            )
              b-form-input#medium-price-input.input(
                v-model="formPrecios.mediumShippingPrice"
                type="number"
              )
          hr.linea
      .text-right
        b-button(type="submit" variant="status-approved") Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'
import SelectOptionsWithdrawals from './SelectOptionsWithdrawals.vue'
import { ADMIN_ROLE } from '@/constants/userRoles'
import { checkRole } from '@/utils/authUtils'
import Compressor from 'compressorjs'
import PackageLabelTemplate from '@/components/withdrawal/printPackages/PackageLabelTemplate.vue'

export default {
  mixins: [MakeToastMixin],
  components: {
    SelectOptionsWithdrawals,
    PackageLabelTemplate
  },
  data () {
    return {
      ADMIN_ROLE,
      requires: false,
      priceSelectedRegion: '',
      role: '',
      precioEspecial: '',
      communes: [],
      regions: [],
      file: null,
      urlLogoStore: null,
      formPrecios: {
        idRegion: '',
        idStore: '',
        urbanRetirementPrice: '',
        ruralRetirementPrice: '',
        mediumShippingPrice: '',
        smallShippingPrice: ''
      },
      form: {
        storeName: '',
        withdrawalAddress: null,
        withdrawalAdgent: null,
        withdrawalCommuneId: null,
        line: null,
        pickupPhone1: null,
        pickupPhone2: null,
        companyName: null,
        rut: null,
        billingAddress: null,
        billingCommune: null,
        businessLine: null,
        billingPhone: null,
        internalCode: null,
        noRequiresVoucher: false,
        noRequiresMin: false,
        customizableFieldOne: null,
        customizableFieldTwo: null,
        logoUrl: null
      },
      backendErrors: {}
    }
  },
  validations () {
    const form = {
      storeName: { required },
      withdrawalAddress: { required },
      withdrawalAdgent: { required },
      withdrawalCommuneId: { required },
      pickupPhone1: { required },
      line: { required }
    }
    return { form }
  },
  async created () {
    const storeId = this.user.role === 'store' ? this.user.storeId : this.$route.params.storeId
    this.formPrecios.idStore = storeId
    const store = await this.getStore({ storeId: storeId })
    this.form = store.data
    this.communes = await this.listActiveCommunes()
    this.regions = await this.listRegion()
    // obtener roll de usuario
    const user = JSON.parse(localStorage.getItem('user')).user
    this.role = `${user.role}`
    // valida si existe comuna
    let nameComune = ''
    if (store.data.logoUrl) {
      this.urlLogoStore = store.data.logoUrl
      const paddingLogo = document.querySelector('.logo-container')
      paddingLogo.style.padding = '5px'
    }
    if (store.data.commune) {
      nameComune = store.data.commune.name
      // busca el id de la region
      const comunas = await this.listActiveCommunes()
      for (var i = 0; i < comunas.length; i++) {
        if (comunas[i].name === nameComune) {
          const regionid = comunas[i].regionId
          try {
            // consulta si existen precios especiales
            const precios = await this.getRegionStore({ idRegion: regionid, idStore: storeId })
            this.formPrecios = precios.data
            this.precioEspecial = true
          } catch {
            // inserta precios segun region
            const preciosGenerales = await this.getRegion({ regionId: regionid })
            this.formPrecios.idRegion = regionid
            this.formPrecios.mediumShippingPrice = preciosGenerales.data.mediumShippingPrice
            this.formPrecios.ruralRetirementPrice = preciosGenerales.data.ruralRetirementPrice
            this.formPrecios.smallShippingPrice = preciosGenerales.data.smallShippingPrice
            this.formPrecios.urbanRetirementPrice = preciosGenerales.data.urbanRetirementPrice
            this.precioEspecial = false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['store', 'user']),
    regionOptions () {
      return this.regions.data.map(region => {
        return {
          text: region.name,
          value: region.id
        }
      })
    },
    communeOptions () {
      return this.communes.map(commune => {
        return {
          text: commune.name,
          value: commune.id
        }
      }).concat({
        text: 'Selecciona la Comuna',
        value: null
      })
    },
    packageLabelContext () {
      return {
        storeName: this.form.storeName,
        customerName: 'David González',
        customerPhone: '9741XXXX',
        customerFullAddress: 'Av Fernando Castillo Velasco 9925',
        observations: 'Dejar en recepción',
        customizableFieldOne: this.form.customizableFieldOne || 'www.12horasenvios.com',
        customizableFieldTwo: this.form.customizableFieldTwo || '+569 5159 3606',
        code: 'N1311240001',
        commune: 'La Reina',
        tags: 'Devolución',
        logoUrl: this.urlLogoStore || null
      }
    }
  },
  methods: {
    checkRole,
    ...mapActions(['updateStore', 'listActiveCommunes', 'getStore', 'listRegion', 'getRegionStore', 'getRegion', 'createRegionStore', 'updateRegionStore', 'getCommune', 'updateLogoStore']),
    async SelectCommunePrice () {
      const idRegion = this.formPrecios.idRegion
      const storeId = this.user.role === 'store' ? this.user.storeId : this.$route.params.storeId
      try {
        console.log(idRegion + '--' + storeId)
        const precios = await this.getRegionStore({ idRegion: idRegion, idStore: storeId })
        if (precios.data !== null) {
          this.formPrecios = precios.data
          this.precioEspecial = true
        }
      } catch {
        const preciosGenerales = await this.getRegion({ regionId: idRegion })
        this.formPrecios.mediumShippingPrice = preciosGenerales.data.mediumShippingPrice
        this.formPrecios.ruralRetirementPrice = preciosGenerales.data.ruralRetirementPrice
        this.formPrecios.smallShippingPrice = preciosGenerales.data.smallShippingPrice
        this.formPrecios.urbanRetirementPrice = preciosGenerales.data.urbanRetirementPrice
        this.precioEspecial = false
      }
    },
    async onSubmit () {
      if (this.form.logo) {
        const formData = new FormData()
        const storeId = this.store.id
        await new Promise((resolve, reject) =>
          new Compressor(this.form.logo, {
            quality: 0.75,
            convertSize: 500000,
            convertTypes: ['image/png', 'image/webp', 'image/jpg', 'image/jpeg'],
            height: 1024,
            success (result) {
              console.log('comprimiendo imagen...')
              const lastDot = result.name.lastIndexOf('.')
              const extension = result.name.substring(lastDot + 1)
              formData.append('file', result, `IMG_Logo-Store_${storeId}.${extension}`)
              resolve(result)
            },
            error (err) {
              console.log(err.message)
              reject(err)
            }
          })
        ).then(response => {
          console.log('imagen comprimida', response)
          console.log(formData)
        })
        const response = await this.updateLogoStore({ data: formData, storeId: this.store.id })
        if (response.status === 200) {
          console.log('imagen subida')
        } else {
          console.log('error', response)
        }
      }
      this.backendErrors = {}
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        const data = this.form
        delete data.logoUrl
        const response = await this.updateStore({ storeId: this.store.id, data: data })
        if (response.status === 200) {
          const storeId = this.user.role === 'store' ? this.user.storeId : this.$route.params.storeId
          await this.getStore({ storeId: storeId })
          this.makeSuccessToast('La tienda se editó correctamente')
          const region = await this.getCommune({ communeId: this.form.withdrawalCommuneId })
          this.formPrecios.idRegion = region.data.regionId
        } else if (response.status >= 400) {
          this.backendErrors = response.data
          this.makeDangerToast(response.data.message || 'Error al editar la tienda')
        }
      }
    },
    async onSubmitPrice () {
      const preciosGenerales = await this.getRegion({ regionId: this.formPrecios.idRegion })
      if (this.formPrecios.mediumShippingPrice === '') {
        this.formPrecios.mediumShippingPrice = preciosGenerales.data.mediumShippingPrice
      }
      if (this.formPrecios.ruralRetirementPrice === '') {
        this.formPrecios.ruralRetirementPrice = preciosGenerales.data.ruralRetirementPrice
      }
      if (this.formPrecios.smallShippingPrice === '') {
        this.formPrecios.smallShippingPrice = preciosGenerales.data.smallShippingPrice
      }
      if (this.formPrecios.urbanRetirementPrice === '') {
        this.formPrecios.urbanRetirementPrice = preciosGenerales.data.urbanRetirementPrice
      }
      console.log(this.formPrecios)
      if (this.formPrecios.idRegion) {
        if (!this.precioEspecial) {
          const response = await this.createRegionStore({ data: this.formPrecios })
          if (response.status === 200 | response.status === 201) {
            this.makeSuccessToast('Los precios se crearon correctamente')
            this.precioEspecial = true
          } else if (response.status >= 400) {
            this.makeDangerToast(response.data.message || 'Error al crear los precios')
          }
        } else {
          const response = await this.updateRegionStore({ data: this.formPrecios, idRegion: this.formPrecios.idRegion, idStore: this.formPrecios.idStore })
          if (response.status === 200) {
            this.makeSuccessToast(response.data.message || 'Los precios se editaron correctamente')
          } else if (response.status >= 400) {
            this.makeDangerToast(response.data.message || 'Error al editar los precios')
          }
        }
      } else {
        this.makeDangerToast('No hay una region seleccionada')
      }
    },
    setFile (event) {
      this.file = event
      if (event) {
        const objectUrl = URL.createObjectURL(event)
        this.urlLogoStore = objectUrl
        const paddingLogo = document.querySelector('.logo-container')
        paddingLogo.style.padding = '5px'
      }
    },
    showField (value) {
      // console.log(value)
    }
  }
}
</script>
<style lang="scss" scoped>
#store-form {
  .box-form {
    align-items: center;
  }
  .fila {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
}
.footer {
  gap: 10px;
  align-items: center;
  margin: auto;
  width: 98%;
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 0 0 20px 20px;
  margin-top: 5mm;
  padding: 0.1cm 0.4cm 0.4cm 0.4cm;
  padding-bottom: 3px !important;
}
.text_1 {
  color: black;
  font-size: 13px;
  text-align: center;
  justify-content: center;
  flex-wrap: nowrap;
  max-width: 60%;
  overflow: hidden;
  word-wrap: break-word;
}
.text_2 {
  color: black;
  font-size: 13px;
  justify-content: center;
  text-align: center;
  flex-wrap: nowrap;
  max-width: 40%;
  overflow: hidden;
  word-wrap: break-word;
}
.package-info {
  font-size: 12px;
  color: black;
  width: 98%;
  border-radius: 20px 20px 0px 0px;
  align-self: center;
  margin-bottom: 10px;
  padding-top: 12px;
  padding-bottom: 4px;
  padding-left: 8px;
  line-height: 1.5;
}
.qr-code {
  text-align: center;
  align-content: center;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  & img {
    width: 130px;
    height: 130px;
  }
}
.logo {
  & img {
    width: 130px;
    height: 130px;
    object-fit: fill;
    overflow: hidden;
    border: none;
  }
}
.logo-container {
  background-color: black;
  padding: 20px;
  overflow: hidden;
  width: max-content;
  border-radius: 50%;
}
.top {
  flex-wrap: nowrap;
  white-space: nowrap;
  margin: auto;
  padding-bottom: 10px;
}
.bottom {
  margin: auto;
}
.etiqueta {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.default-logo {
  width: 100px !important;
  height: 100px !important;
}
.package-code {
  font-size: 14px;
  padding-top: 5px;
  font-weight: 700;
}

.column {
  @media (min-width: 768px) {
    margin-top: -34px;
    overflow-x: hidden;
    margin-left: 60px;
    display: flex;
  }
}
.etiqueta {
  @media (min-width: 768px) {
    width: 57%;
  }
}

</style>
