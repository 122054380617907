<template lang="pug">
      TabDelivery
</template>

<script>
import TabDelivery from '../components/delivery/TabDelivery.vue'
export default {
  components: {
    TabDelivery
  }
}
</script>
