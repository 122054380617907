<template lang="pug">
  b-form(@submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input location_on
      b-form-group.input-title(
        label-for="name-input"
        label="Nombre"
        invalid-feedback="Este campo es requerido"
      )
        b-form-input#name-input.input(
          v-model="form.name"
          maxlength="100"
          :state="$v.$dirty ? !$v.form.name.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input store
      b-form-group.input-title(
        label="Tiendas"
        label-for="type-input"
        invalid-feedback="Este campo es requerido"
      )
        b-form-input(list="store-input" @change="storeSelect" placeholder="Seleccionar tienda")
        b-form-datalist#store-input.input(:options="storesOptions")
    hr.linea
    div.ml-2 Tiendas seleccionadas:
    b-table.ml-3(:items="form.stores" :fields="fields" borderless small responsive)
      template(#cell(store)="{ item }")
        div.d-flex.align-items-center
          div.mr-3 {{item}}
          .material-icons-outlined(style="font-size:18px;color:var(--status-rejected);cursor:pointer" @click="deleteStore(item)") cancel
    .text-right
      b-button(type="submit") Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin.vue'

export default {
  mixins: [MakeToastMixin],
  props: {
    group: {
      type: Object,
      default: null
    },
    editMode: Boolean
  },
  data () {
    return {
      stores: [],
      form: {
        name: this.editMode ? this.group.name : '',
        stores: []
      },
      fields: [
        { label: '', key: 'store' },
        { label: '', key: 'delete' }
      ]
    }
  },
  validations () {
    const form = {
      name: { required }
    }
    return { form }
  },
  async created () {
    if (this.editMode) {
      this.form.stores = this.group.stores.map(store => store.name)
    }
    this.stores = await this.listAllStoreName()
  },
  methods: {
    ...mapActions(['createGroup', 'updateGroup', 'getGroup', 'listAllStoreName']),
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          this.onUpdateGroup()
        } else {
          this.onCreateGroup()
        }
      }
    },
    async onCreateGroup () {
      const response = await this.createGroup({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('El grupo se creó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear el grupo')
      }
    },
    async onUpdateGroup () {
      const response = await this.updateGroup({ groupId: this.group.id, data: this.form })
      if (response.status === 200) {
        this.makeSuccessToast('El grupo se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar el grupo')
      }
    },
    storeSelect (select) {
      this.form.stores.push(select)
    },
    deleteStore (item) {
      this.form.stores = this.form.stores.filter(store => store !== item)
    }
  },
  computed: {
    storesOptions () {
      return this.stores.map(store => {
        return {
          value: store.name,
          text: store.name
        }
      })
    }
  }
}
</script>
<style scoped>
.box-form {
  align-items: center;
}
</style>
