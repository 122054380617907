import axios from '../../plugins/axios'

export const listMessage = ({ commit }, { params } = {}) => {
  return axios.get('/message', { params })
    .then((response) => {
      commit('SET_MESSAGES', response.data)
      return response
    })
    .catch(err => err.response)
}

export const createMessage = (_, { data }) => {
  return axios.post('/message', data)
    .then(response => response)
    .catch(err => err.response)
}

export const getMessage = ({ commit }, { messageId } = {}) => {
  return axios.get(`message/${messageId}`)
    .then((response) => {
      commit('SET_MESSAGE', { message: response.data })
      return response
    })
    .catch(err => err.response)
}

export const getMessageByGroup = ({ commit }, { groupId } = {}) => {
  return axios.get(`message-by-group/${groupId}`)
    .then((response) => {
      commit('SET_MESSAGES', response.data)
      return response.data
    })
    .catch(err => err.response)
}

export const deleteMessage = (_, { messageId }) => {
  return axios.delete(`/message/${messageId}`)
    .then(response => response)
    .catch(err => err.response)
}

export const updateMessage = (_, { messageId, data }) => {
  return axios.put(`/message/${messageId}`, data)
    .then(response => response)
    .catch(err => err.response)
}
