<template lang="pug">
  b-form(@submit.stop.prevent="onSubmit")
    .box-form
      .material-icons.icon-input subject
      b-form-group.input-title(
        label-for="title-input"
        label="Título"
        invalid-feedback="Este campo es requerido"
      )
        b-form-input#title-input.input(
          v-model="form.subject"
          maxlength="100"
          :state="$v.$dirty ? !$v.form.subject.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input text_snippet
      b-form-group.input-title(
        label-for="text-input"
        label="Contenido"
        invalid-feedback="Este campo es requerido"
      )
        b-form-textarea#text-input.input(
          v-model="form.text"
          maxlength="100"
          :state="$v.$dirty ? !$v.form.text.$invalid : null"
        )
    hr.linea
    .box-form
      .material-icons.icon-input message
      b-form-group.input-title(
        label="Grupos"
        label-for="type-input"
        invalid-feedback="Este campo es requerido"
      )
        b-form-input(list="group-input" @change="groupSelect" placeholder="Seleccionar grupo")
        b-form-datalist#group-input.input(:options="groupsOptions")
    hr.linea
    .box-form
      .material-icons.icon-input
      b-form-group.input-title(
        label-for="active-check"
        label="Activo"
      )
        b-form-checkbox#active-check.input(
          v-model="form.is_active"
        )
    .box-form
      .material-icons.icon-input
      b-form-group.input-title(
        label-for="universal-check"
        label="Mensaje universal"
      )
        b-form-checkbox#universal-check.input(
          v-model="form.is_universal"
        )
    div.ml-2 Grupos seleccionados:
    b-table.ml-3(:items="form.groups" :fields="fields" borderless small responsive)
      template(#cell(group)="{ item }")
        div.d-flex.align-items-center
          div.mr-3 {{item}}
          .material-icons-outlined(style="font-size:18px;color:var(--status-rejected);cursor:pointer" @click="deleteGroup(item)") cancel
    .text-right
      b-button(type="submit") Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin.vue'

export default {
  mixins: [MakeToastMixin],
  props: {
    message: {
      type: Object,
      default: null
    },
    editMode: Boolean
  },
  data () {
    return {
      groups: [],
      form: {
        subject: this.editMode ? this.message.subject : '',
        text: this.editMode ? this.message.text : '',
        is_active: this.editMode ? this.message.is_active : true,
        is_universal: this.editMode ? this.message.is_universal : false,
        groups: []
      },
      fields: [
        { label: '', key: 'group' },
        { label: '', key: 'delete' }
      ]
    }
  },
  validations () {
    const form = {
      text: { required },
      subject: { required }
    }
    return { form }
  },
  async created () {
    if (this.editMode) {
      this.form.groups = this.message.groups.map(group => group.name)
      console.log(this.message)
    }
    this.groups = await this.listAllGroupName()
  },
  methods: {
    ...mapActions(['createMessage', 'updateMessage', 'getMessage', 'listAllGroupName']),
    onSubmit () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        if (this.editMode) {
          this.onUpdateMessage()
        } else {
          this.onCreateMessage()
        }
      }
    },
    async onCreateMessage () {
      const response = await this.createMessage({ data: this.form })
      if (response.status === 201) {
        this.makeSuccessToast('El mensaje se creó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al crear el mensaje')
      }
    },
    async onUpdateMessage () {
      const response = await this.updateMessage({ messageId: this.message.id, data: this.form })
      if (response.status === 200) {
        this.makeSuccessToast('El mensaje se editó correctamente')
        this.$emit('done')
      } else if (response.status >= 400) {
        this.makeDangerToast(response.data.message || 'Error al editar el mensaje')
      }
    },
    groupSelect (select) {
      this.form.groups.push(select)
    },
    deleteGroup (item) {
      this.form.groups = this.form.groups.filter(group => group !== item)
    }
  },
  computed: {
    groupsOptions () {
      return this.groups.map(group => {
        return {
          value: group.name,
          text: group.name
        }
      })
    }
  }
}
</script>
<style scoped>
.box-form {
  align-items: center;
}
</style>
