import axios from '../../plugins/axios'
import fileDownload from 'js-file-download'
import moment from 'moment-timezone'

export const listDelivery = ({ commit }, { params } = {}) => {
  return axios.get('/delivery', { params })
    .then(response => {
      commit('SET_DELIVERIES', response.data.rows)
      commit('SET_COUNT_STATUS', response.data.countStatus)
      return response
    })
    .catch(err => err.response)
}

export const downloadExcelDelivery = (_, { params } = {}) => {
  return axios.get('/delivery-excel', { params, responseType: 'blob' })
    .then(response => {
      const format = (date) => {
        return moment(date).format('DD-MM-YYYY')
      }
      const date = params.startDate
        ? format(params.startDate) + '_al_' + format(params.endDate)
        : format(params.date)
      fileDownload(response.data, `${date}_Seguimiento.xlsx`)
      return response
    })
    .catch(err => err.response)
}

export const deleteDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.delete(`/delivery/${payload.id}`, config)
    .then(response => response)
    .catch(err => err.response)
}

export const updateDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.put(`/delivery/${payload.id}`, payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const bulkCreateDelivery = (_, payload) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.post('/bulk_delivery', payload.data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const getMyDeliveries = (_) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axios.get('/my-delivery', config)
    .then(response => response)
    .catch(err => err.response)
}

export const generatePDF = (_, payload) => {
  return axios.post('/generate-pdf', payload)
    .then(response => response)
    .catch(err => err.response)
}

export const completeDelivery = (_, { data, deliveryId }) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' } }
  return axios.put(`/complete-delivery/${deliveryId}`, data, config)
    .then(response => response)
    .catch(err => err.response)
}

export const deliveryTracking = (_, payload) => {
  return axios.post('/delivery-tracking', payload)
    .then(response => response)
    .catch(err => err.response)
}
