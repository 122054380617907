<template lang="pug">
  div
    b-row.my-4(align-h="between")
      b-col.mb-2
        h3.d-inline Comunas
      b-col(md="auto")
        b-button(v-b-modal.create-commune-form-modal) Ingresar Nuevo
    b-table(:fields="fields" :items="communeData" responsive)
      template(#cell(actions)="{value}")
        .material-icons-outlined.btn-icon.mr-2.mb-1(@click="openEditModal(value)") edit
        .material-icons-outlined.btn-icon.mr-2.mb-1.boton-eliminar(@click="onDeleteCommune(value)") delete_outline
    b-modal#create-commune-form-modal(ref="createCommuneFormModal" hide-footer title="Creando Comuna")
      commune-form(@done="onDoneCommuneModal")
    b-modal(ref="updateCommuneFormModal" hide-footer title="Editando Comuna")
      commune-form(v-if="selectedCommune" @done="onDoneCommuneModal" :commune="selectedCommune" :editMode="true")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CommuneForm from './CommuneForm'
import MakeToastMixin from '../../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  components: {
    CommuneForm
  },
  data () {
    return {
      selectedCommune: null,
      fields: [

        { label: 'Nombre', key: 'name' },
        { label: 'Tipo', key: 'type' },
        { label: 'Activo', key: 'active' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listCommune', 'deleteCommune']),
    onDoneCommuneModal () {
      this.fetchData()
      this.$refs.createCommuneFormModal.hide()
      this.$refs.updateCommuneFormModal.hide()
      this.$emit('done')
    },
    openEditModal (commune) {
      this.selectedCommune = commune
      this.$refs.updateCommuneFormModal.show()
    },
    async fetchData () {
      await this.listCommune({ regionId: this.$route.params.regionId })
    },
    async onDeleteCommune (commune) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar ${commune.name}?`)
      if (!ok) return

      const response = await this.deleteCommune({ id: commune.id })
      if (response.status === 200) {
        this.makeSuccessToast('La comuna se eliminó correctamente')
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar la comuna')
      }
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters(['communes']),
    communeData () {
      return this.communes.reduce((acum, commune) => acum.concat({
        name: commune.name,
        code: commune.code,
        type: commune.type === 'urban' ? 'Urbana' : 'Rural',
        active: commune.active ? 'Si' : 'No',
        actions: commune
      }), [])
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
