import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const MessageStore = {
  state: {
    messages: [],
    message: {}
  },
  getters,
  mutations,
  actions
}

export default MessageStore
