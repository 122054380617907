<template lang="pug">
  #store-data(v-if="store")
    .box
        h5 Fecha
        div.subtitle Selecciona la fecha en la que quieres agendar el retiro.*
        b-form
          b-form-group(
            invalid-feedback="Este campo es requerido"
          )
            strong(style="color:var(--success)") {{ (store && store.alwaysAcceptWithdrawals) || statusStores.status === 'accept' ? 'Aceptando retiros fuera de horario': '' }}
            strong(style="color:var(--info)") {{ (store && !store.alwaysAcceptWithdrawals && store.alwaysRejectWithdrawals) || statusStores.status === 'reject' ? 'Lo siento, no estamos aceptando retiros por ahora': '' }}
            div(v-if="statusStores.status !== 'reject'")
              b-form-datepicker(
                v-if="!store.alwaysRejectWithdrawals || store.alwaysAcceptWithdrawals && store.alwaysRejectWithdrawals"
                v-model="retirementDate"
                style="width:300px"
                placeholder="Fecha"
                locale="es"
                start-weekday=1
                :date-disabled-fn="dateDisabled"
                :min="minDate()"
                label-help="Usa el cursor para navegar por el calendario"
                hide-header
                :state="$v.$dirty ? !$v.retirementDate.$invalid : null")
        div(v-if="getHour() || scheduleError")
          center
            div Lo sentimos, ya estamos fuera de horario para la fecha de retiro seleccionada, prueba una fecha posterior para agendar.
        div(v-else)
            .card-info.my-4.p-3
              .header.d-flex.justify-content-between
                .card-title(v-b-toggle.accordion-retiro) Datos del Retiro
                .material-icons-outlined(v-b-toggle.accordion-retiro) {{showRetiro ? 'arrow_drop_up' : 'arrow_drop_down' }}
              b-collapse(id="accordion-retiro" v-model="showRetiro")
                .d-flex.justify-content-between
                  div.subtitle Confirma o edita los datos del retiro.
                  b-button.edit-btn(v-if="user.role !== 'admin'" :to="'/mi-tienda#retirement-data'" variant="link" target="_blank")
                  b-button.edit-btn(v-if="user.role === 'admin'" :to="'/tiendas/' + store.id" variant="link" target="_blank")
                    .material-icons-outlined edit
                b-row.fila
                  b-col
                    div {{store.storeName ? store.storeName : '' }}
                  b-col
                    div {{store.withdrawalAdgent ? store.withdrawalAdgent : '' }}
                  b-col
                    div {{store.line ? store.line : '' }}
                b-row.fila.mt-2
                  b-col
                    div {{store.withdrawalAddress ? store.withdrawalAddress : '' }}
                  b-col
                    div {{store.commune ? store.commune.name : ''}}
                  b-col
                    div {{store.pickupPhone1 ? store.pickupPhone1 : '' }}
                    br
            div(v-if="!store.commune || !store.commune.name")
              center
                div.colorRed No existen datos de retiro para esta tienda, si desea continuar debe ingresar los datos de retiro
                br
            b-form-checkbox(v-model="requires") Requiere Facturación
            .card-info.my-4.p-3(v-if="requires")
              .header.d-flex.justify-content-between
                .card-title(v-b-toggle.accordion-facturacion) Datos de Facturación
                .material-icons-outlined(v-b-toggle.accordion-facturacion) {{showRetiro ? 'arrow_drop_up' : 'arrow_drop_down' }}
              b-collapse(id="accordion-facturacion" v-model="showFacturacion")
                .d-flex.justify-content-between
                  div.subtitle Confirma o edita los datos de facturación.
                  b-button.edit-btn(:to="'/mi-tienda#billing-data'" variant="link" target="_blank")
                    .material-icons-outlined edit
                b-row.fila
                  b-col
                    div {{store.companyName}}
                  b-col
                    div {{store.rut}}
                  b-col
                    div {{store.businessLine}}
                b-row.fila.mt-2
                  b-col
                    div {{store.billingAddress}}
                  b-col
                    div {{store.billingCommune}}
                  b-col
                    div {{store.billingPhone}}
    .footer.d-flex.justify-content-between.my-4
      b-button(variant="outline-primary" size="sm" @click="$bvModal.hide('create-withdrawal-form-modal')") Cancelar
      div(v-if="statusStores.status !== 'reject'")
        b-button(variant="status-approved" size="sm" @click="save()" v-if="!store.alwaysRejectWithdrawals || store.alwaysRejectWithdrawals && store.alwaysAcceptWithdrawals && store.commune" :disabled="scheduleError") Continuar
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      requires: false,
      showRetiro: true,
      showFacturacion: true,
      retirementDate: null,
      scheduleError: false,
      calendarLabel: false,
      errorReason: ''
    }
  },
  validations: {
    retirementDate: { required }
  },
  methods: {
    ...mapActions(['checkWithdrawalHour', 'getStatusStore']),
    save () {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        localStorage.setItem('retirementDate', this.retirementDate)
        this.$emit('continue', true)
      }
    },
    dateDisabled (ymd, date) {
      const weekday = date.getDay()
      return weekday === 0
    },
    minDate () {
      return this.$moment(new Date()).format('YYYY-MM-DD')
    },
    getHour () {
      if (!this.scheduleError) return false
      const limit = this.$moment('11:59pm', 'h:mma') // antes 9:00pm
      const actual = this.$moment()
      const tomorrow = this.$moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
      if (this.retirementDate === tomorrow && actual.isAfter(limit)) {
        return true
      }
      return false
    },
    async checkStoreConditions () {
      this.scheduleError = false
      if (!this.store) return
      if (!this.retirementDate) return

      // revisa día seleccionado
      const { canSchedule, reason } = await this.checkWithdrawalHour({ date: this.retirementDate, storeId: this.store.id })
      // console.log('checkStore', canSchedule, reason)
      if (!canSchedule) {
        this.scheduleError = true
        this.errorReason = reason
      }
      // revisa comuna
      if (!this.store.commune || !this.store.commune.name) {
        console.log('no hay comuna de retiro seteada')
        this.scheduleError = true
        this.errorReason = 'Comuna de retiro mal definida'
      }
      if (!this.scheduleError) {
        // console.log(this.retirementDate, 'retirementDate')
        localStorage.setItem('retirementDate', this.retirementDate)
      }
    }
  },
  computed: {
    ...mapGetters(['withdrawal', 'store', 'user', 'statusStores'])
  },
  async created () {
    await this.getStatusStore()
    // console.log(this.statusStores)
  },
  watch: {
    async retirementDate () {
      await this.checkStoreConditions()
    },
    async store () {
      await this.checkStoreConditions()
    }
  }
}
</script>
<style lang="scss" scoped>
#store-data {
  .colorRed {
    color: red;
  }
  .box {
    margin: auto 120px;
    @media (max-width: 992px) {
      margin: -20px;
    }
  }
  .fila {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .edit-btn {
    margin-bottom: -38px;
    margin-top: 20px;
    z-index: 100;
  }
}
</style>
