import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const GroupStore = {
  state: {
    groups: [],
    group: {}
  },
  getters,
  mutations,
  actions
}

export default GroupStore
