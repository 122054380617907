<template lang="pug">
  b-form.mx-5(@submit="onSubmit")
    b-input-group.mb-3(label-for="input-email")
      b-input-group-prepend(is-text)
        .material-icons.icon person
      b-form-input(
        id="input-email"
        type="email"
        placeholder="Ingresa tu Mail"
        v-model="form.email"
        required
        )
    b-input-group.mb-3(label-for="input-password" style="color:white")
      b-input-group-prepend(is-text)
        .material-icons.icon key
      b-form-input(
        id="input-password"
        type="password"
        placeholder="Ingresa tu Contraseña"
        v-model="form.password"
        required)
    .text-center
      router-link.recover(:to="{ name: 'Recover Password' }") ¿Olvidaste tu contraseña?
    .text-center.mt-5
      b-button(type="submit" style="width: 240px;"  variant="status-approved") Ingresar
</template>
<script>
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      form: {
        email: '',
        password: ''
      },
      error: ''
    }
  },
  methods: {
    ...mapActions(['login']),
    async onSubmit (event) {
      event.preventDefault()
      this.form.email.toLowerCase()
      const response = await this.login(this.form)
      if (!response) {
        this.makeDangerToast(response || 'Credenciales incorrectas')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../style/colors.scss';
#Login {
  border: 0;
  margin: auto;
  min-width: max-content;
  height: 440px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  .group {
    width: 100%;
  }
  .buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    padding: 0 32px;
  }
  .recover {
    color: var(--secondary);
  }
  hr{
    background: $card-bg;
    margin-top: -10px;
  }
}
</style>
