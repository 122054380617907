<template lang="pug">
  b-container(fluid="lg")
    .box.sombra(v-if="region")
      h3 {{region.name}}
      region-form.mt-5(v-if="region" :region="region" :editMode="true")
      list-communes
</template>

<script>
import RegionForm from '../components/region/RegionForm'
import ListCommunes from '../components/region/commune/ListCommunes'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    RegionForm,
    ListCommunes
  },
  methods: {
    ...mapActions(['getRegion'])
  },
  computed: {
    ...mapGetters(['region'])
  },
  async created () {
    this.getRegion({ regionId: this.$route.params.regionId })
  }
}
</script>
