<template lang="pug">
    b-modal#add-store(size="xl" hide-footer)
            template(#modal-title='') Nueva Tienda
            .d-block.text-center
            #store-form
            b-form(@submit.stop.prevent="addStore")
                b-row#retirement-data.fila
                  b-col(md="6")
                    .box-form
                      .material-icons-outlined.icon-input-modal store
                      b-form-group.input-title(
                          label-for="store-name-input"
                          label="Nombre de la tienda"
                          invalid-feedback="Este campo es requerido"
                      )
                          b-form-input#store-name-input.input(
                          v-model="form.storeName"
                          maxlength="256"
                          placeholder="Ingresa el nombre de la tienda"
                          :state="$v.$dirty ? !$v.form.storeName.$invalid : null"
                          required
                          )
                  b-col(md="6")
                    .box-form
                      .material-icons.icon-input-modal account_box
                      b-form-group.input-title(
                          label="Nombre del Encargado"
                          label-for="withdrawal-adgent-input"
                          invalid-feedback="Este campo es requerido"
                      )
                          b-form-input#withdrawal-adgent-input.input(
                          v-model="form.withdrawalAdgent"
                          maxlength="256"
                          placeholder="Ingresa en Nombre del Encargado"
                          :state="$v.$dirty ? !$v.form.withdrawalAdgent.$invalid : null"
                          required
                          )
                b-row
                  b-col(md="6")
                    .box-form
                      .material-icons.icon-input-modal local_offer
                      b-form-group.input-title(
                          label="Rubro"
                          label-for="line-input"
                          invalid-feedback="Este campo es requerido"
                      )
                          b-form-input#line-input.input(
                          v-model="form.line"
                          placeholder="Ingresa el Rubro"
                          maxlength="256"
                          :state="$v.$dirty ? !$v.form.line.$invalid : null"
                          required
                          )
                  b-col(md="6")
                    .box-form
                      .material-icons.icon-input-modal fork_left
                      b-form-group.input-title(
                          label="Dirección de Retiro"
                          label-for="withdrawal-address-input"
                          invalid-feedback="Este campo es requerido"
                      )
                          b-form-input#withdrawal-address-input.input(
                          v-model="form.withdrawalAddress"
                          placeholder="Ingresa la Calle y el Número"
                          maxlength="256"
                          :state="$v.$dirty ? !$v.form.withdrawalAddress.$invalid : null"
                          required
                          )
                b-row
                  b-col(md="6")
                    .box-form
                      .material-icons-outlined.icon-input-modal location_on
                      b-form-group.input-title(
                          label="Comuna"
                          label-for="withdrawal-commune-input"
                          invalid-feedback="Este campo es requerido"
                      )
                          b-form-select#withdrawal-commune-input.input(
                          v-model="form.withdrawalCommuneId"
                          :options="communeOptions"
                          placeholder="Ingresa la Comuna"
                          :state="$v.$dirty ? !$v.form.withdrawalCommuneId.$invalid : null"
                          required
                          )
                  b-col(md="6")
                      .box-form
                        .material-icons-outlined.icon-input-modal phone
                        b-form-group.input-title(
                            label="Teléfono"
                            label-for="pickup-phone1-input"
                            invalid-feedback="Este campo es requerido"
                        )
                            b-form-input#pickup-phone-1-input.input(
                            v-model="form.pickupPhone1"
                            placeholder="Ingresa Teléfono"
                            maxlength="256"
                            :state="$v.$dirty ? !$v.form.pickupPhone1.$invalid : null"
                            required
                            )
                b-row
                  b-col(md="6")
                        .box-form
                          .material-icons-outlined.icon-input-modal phone
                          b-form-group.input-title(
                              label="Teléfono (Opcional)"
                              label-for="pickup-phone1-input"
                          )
                              b-form-input#pickup-phone-1-input.input(
                              v-model="form.pickupPhone2"
                              maxlength="256"
                              placeholder="Ingresa Teléfono"
                              )
                  b-col(md="6")
                    .box-form
                      .material-icons-outlined.icon-input-modal key
                      b-form-group.input-title(
                          label-for="store-name-input"
                          label="Código de la tienda"
                      )
                          b-form-input#store-name-input.input(
                          v-model="form.internalCode"
                          maxlength="256"
                          @input="onInternalCodeInput"
                          placeholder="Ingresa el código de la tienda"
                          required
                          )
                          b-form-invalid-feedback(v-if="internalCodeError" class="error-message")
                          | {{ internalCodeError }}
                b-row
                b-col(cols="12")
                    b-form-checkbox.mt-2.mb-(v-model="form.noRequiresMin" v-if="user.role === 'admin'") No Requiere de mínimo 3 envíos
                    b-form-checkbox#checkbox-1(v-if="user.role === 'admin'" v-model="form.noRequiresVoucher") No Requiere Comprobante de Pago
                b-row
                b-col(cols="12").d-flex.justify-content-end
                    b-button(
                    type="submit"
                    :disabled="loading"
                    spinner-small
                    variant="status-approved"
                    ).mt-2 Guardar
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ModalAddStore',
  data () {
    return {
      form: {
        storeName: null,
        withdrawalAdgent: null,
        withdrawalAddress: null,
        withdrawalCommuneId: null,
        pickupPhone1: null,
        pickupPhone2: null,
        internalCode: null,
        noRequiresVoucher: false,
        noRequiresMin: false
      },
      communes: [],
      loading: false,
      internalCodeError: ''
    }
  },
  validations () {
    const form = {
      storeName: { required },
      withdrawalAdgent: { required },
      withdrawalAddress: { required },
      withdrawalCommuneId: { required },
      pickupPhone1: { required }
    }
    return { form }
  },
  async created () {
    this.communes = await this.listActiveCommunes()
  },
  computed: {
    ...mapGetters(['user']),
    communeOptions () {
      return this.communes.map(commune => {
        return {
          text: commune.name,
          value: commune.id
        }
      }).concat({
        text: 'Selecciona la Comuna',
        value: '',
        disabled: true,
        selected: true
      })
    }
  },
  methods: {
    ...mapActions(['listActiveCommunes', 'createStore', 'validateInternalCode']),
    async addStore () {
      this.loading = true
      const response = await this.createStore({ data: this.form })
      try {
        if (response.ok) {
          this.$bvToast.toast(response.data.message, {
            title: 'Éxito',
            variant: 'success',
            solid: true
          })
          await new Promise(resolve => setTimeout(resolve, 1000))
          this.$router.push(`/tiendas/${response.data.id}`)
        } else {
          this.$bvToast.toast(response.data.message, {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        }
      } catch (error) {
        this.$bvToast.toast('Error al crear la tienda', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      } finally {
        this.loading = false
      }
    },
    async onInternalCodeInput () {
      const internalCode = this.form.internalCode
      if (internalCode.length >= 3) {
        const response = await this.validateInternalCode({ params: { internalCode: internalCode } })
        if (response.codeFind === 0) {
          this.internalCodeError = ''
          this.loading = false
        } else {
          this.internalCodeError = 'El código de la tienda ya existe'
          this.loading = true
        }
      }
    }
  }
}
</script>
