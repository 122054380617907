<template lang="pug">
  delivery-complete(:delivery="delivery")
</template>

<script>
import DeliveryComplete from '../components/delivery/DeliveryComplete.vue'
export default {
  components: {
    DeliveryComplete
  },
  data () {
    return {
      delivery: {}
    }
  },
  created () {
    const localData = JSON.parse(localStorage.getItem('vuex'))
    if (localData) {
      this.delivery = localData.delivery.deliverySelected
    }
  }
}
</script>
