const mutations = {
  'SET_DELIVERIES' (state, value) {
    state.deliveries = value
  },
  'SET_COUNT_STATUS' (state, value) {
    state.countStatus = value
  },
  'SET_DELIVERY_SELECTED' (state, value) {
    state.deliverySelected = value
  },
  'SET_TAB_SELECTED' (state, value) {
    state.saveTabSelected = value
  }
}
export default mutations
