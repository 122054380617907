import axios from '../../plugins/axios'

export const login = ({ commit }, user) => {
  return axios.post('/login', user)
    .then((response) => {
      const user = response.data
      commit('SET_USER', { user })
      return response
    },
    (error) => {
      console.error(error)
    }
    )
}

export const logout = ({ commit }) => {
  commit('SET_USER', { user: null })
}

export const sendRecoverPasswordEmail = (_, { email }) => {
  if (email && email !== '') {
    return axios.post('/auth/recover_password_email', { email })
      .catch((error) => {
        console.error(error)
      })
  }
}

export const changeUserPassword = (_, { password, token }) => {
  if (password && password !== '') {
    const header = { headers: { Authorization: `bearer ${token}` } }
    return axios.put('/auth/change_password', { password }, header)
      .catch((error) => {
        console.error(error)
      })
  }
}
