import axios from '../../plugins/axios'

export const listRegionStore = async ({ commit }, { params } = {}) => {
  try {
    const response = await axios.get('/regionStore', { params })
    commit('SET_REGIONSTORES', response.data)
    return response
  } catch (err) {
    return err.response
  }
}

export const createRegionStore = async (_, { data }) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  try {
    const response = await axios.post('/regionStore', data, config)
    return response
  } catch (err) {
    return err.response
  }
}

export const getRegionStore = ({ commit }, { idRegion, idStore } = {}) => {
  try {
    const response = axios.get(`regionStore/${idRegion}/${idStore}`)
    commit('SET_REGIONSTORE', response.data)
    return response
  } catch (err) {
    return err.response
  }
}

export const getRegionsStore = ({ commit }, { idStore } = {}) => {
  try {
    const response = axios.get(`regionStore/${idStore}`)
    commit('SET_REGIONSTORE', response.data)
    return response
  } catch (err) {
    return err.response
  }
}

export const deleteRegionStore = async (_, { idRegion, idStore }) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  try {
    const response = await axios.delete(`/regionStore/${idRegion}/${idStore}`, config)
    return response
  } catch (err) {
    return err.response
  }
}

export const updateRegionStore = async (_, { data, idRegion, idStore }) => {
  const token = JSON.parse(localStorage.getItem('user')).token
  const config = { headers: { Authorization: `Bearer ${token}` } }
  try {
    const response = await axios.put(`/regionStore/${idRegion}/${idStore}`, data, config)
    return response
  } catch (err) {
    return err.response
  }
}
