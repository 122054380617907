import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const CommuneStore = {
  state: {
    communes: [],
    commune: {},
    allCommunes: []
  },
  getters,
  mutations,
  actions
}

export default CommuneStore
