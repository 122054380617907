<template lang="pug">
b-container#StoreView(fluid="lg")
  ListStores
</template>
<script>
import ListStores from '../components/store/ListStores'
export default {
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Inicio',
          to: { name: 'Home' }
        },
        {
          text: 'Tiendas',
          active: true
        }
      ]
    }
  },
  components: {
    ListStores
  }
}
</script>
