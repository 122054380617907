<template lang="pug">
#RecoverPasswordForm
  div
    b-form(@submit="onSubmit" v-if="result === false")
      .group
        b-form-group(
          label-for="input-password"
          style="color:white"
        )

          .title Nueva contraseña
          b-input-group.mb-2(label-for="input-email")
            b-input-group-prepend(is-text)
              .material-icons-outlined.icon lock
            b-form-input.input(
              v-model="password"
              id="input-password"
              type="password"
            )
          b-form-invalid-feedback(:state="$v.password.required") Este campo es requerido
          b-form-invalid-feedback(:state="$v.password.minLength") La contraseña debe contener mmínimo 6 caracteres
      .group
        b-form-group(
          label-for="repeat-password-input"
          style="color:white"
        )
          .title Repetir contraseña
          b-input-group.mb-2(label-for="repeat-password-input")
            b-input-group-prepend(is-text)
              .material-icons-outlined.icon lock
            b-form-input.input(
              v-model="repeatPassword"
              id="repeat-password-input"
              type="password"
            )
          b-form-invalid-feedback(:state="$v.repeatPassword.sameAsPassword") Las contraseñas no coinciden
      center.buttons-auth
        b-button(type="submit") Guardar
    div(v-if="result === true")
      center
        p.texto Tu contraseña ha sido actualizada, haz click en "Volver" para dirigirte al Login
        router-link.recover(:to="{ name: 'Login' }") Volver
</template>
<script>
import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      token: Buffer.from(this.$route.params.token, 'base64').toString(),
      password: '',
      repeatPassword: '',
      result: false
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6)
    },
    repeatPassword: {
      sameAsPassword: sameAs('password')
    }
  },
  methods: {
    ...mapActions(['changeUserPassword']),
    async onSubmit (event) {
      event.preventDefault()
      const password = this.password
      const token = this.token
      const response = await this.changeUserPassword({ password, token })
      if (response) {
        this.result = true
      } else {
        this.makeDangerToast('No se ha podido cambiar la contraseña')
      }
    }
  }
}
</script>
<style lang="scss">
@import '../../style/colors.scss';
#RecoverPasswordForm{
  max-width: 300px;
  margin: auto;
  padding: 16px;
}
.texto {
  font-size: 15px;
}
.buttons-auth {
  margin-top:40px;
  display: flex;
  flex-direction: column;
}
hr{
  background: $card-bg;
  margin-top: -10px !important;
}
.recover {
  color: var(--light-secondary);
}
.title{
  color: var(--primary);
  font-weight: bold;
  font-size: 13px;
}
</style>
