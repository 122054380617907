<template lang="pug">
  div
    b-row.my-4(align-h="between")
      b-col.mb-2
        h3.d-inline Grupos
      b-col(md="auto" cols="12")
        b-button(v-b-modal.create-group-form-modal block) Ingresar Nuevo
    b-table(:fields="fields" :items="groupData" responsive)
      template(#cell(actions)="{item}")
        .material-icons-outlined.btn-icon.mr-2.mb-1(@click="openEditModal(item)") edit
        .material-icons-outlined.btn-icon.mr-2.mb-1.boton-eliminar(@click="onDeleteGroup(item)") delete_outline
      template(#cell(seeStores)="{item}")
        b-button(@click="openSeeGroupsModal(item)" variant="link") Ver
    b-modal#see-stores-group-modal(ref="seeStoresGroupModal" hide-footer title="Tiendas")
      div(v-if="selectedGroup")
        div(v-for="store in selectedGroup.stores") {{store.name}}
    b-modal#create-group-form-modal(ref="createGroupFormModal" hide-footer title="Creando Grupo")
      group-form(@done="onDoneGroupModal")
    b-modal(ref="updateGroupFormModal" hide-footer title="Editando Grupo")
      group-form(v-if="selectedGroup" @done="onDoneGroupModal" :group="selectedGroup" :editMode="true")
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import GroupForm from './GroupForm'
import MakeToastMixin from '../mixins/MakeToastMixin'

export default {
  mixins: [MakeToastMixin],
  components: {
    GroupForm
  },
  data () {
    return {
      selectedGroup: null,
      fields: [

        { label: 'Nombre', key: 'name' },
        { label: 'Tiendas', key: 'seeStores' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listGroup', 'deleteGroup']),
    onDoneGroupModal () {
      this.fetchData()
      this.$refs.createGroupFormModal.hide()
      this.$refs.updateGroupFormModal.hide()
      this.$emit('done')
    },
    openEditModal (group) {
      this.selectedGroup = group
      this.$refs.updateGroupFormModal.show()
    },
    openSeeGroupsModal (group) {
      this.selectedGroup = group
      this.$refs.seeStoresGroupModal.show()
    },
    async fetchData () {
      await this.listGroup()
    },
    async onDeleteGroup (group) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eliminar ${group.name}?`)
      if (!ok) return

      const response = await this.deleteGroup({ groupId: group.id })
      if (response.status === 200) {
        this.makeSuccessToast('El grupo se eliminó correctamente')
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar el grupo')
      }
      this.fetchData()
    }
  },
  computed: {
    ...mapGetters(['groups']),
    groupData () {
      return this.groups.reduce((acum, group) => acum.concat({
        id: group.id,
        name: group.name,
        stores: group.stores
      }), [])
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
