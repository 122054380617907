// Calcula el costo de retiro para una tienda, de acuerdo a la comuna de retiro y el número de paquetes
export default ({ communes, commune, packages, ruralRetirementPrice, urbanRetirementPrice }) => {
  // console.log({ communes, commune, packages, ruralRetirementPrice, urbanRetirementPrice })
  if (!communes || communes.length === 0) return 0
  if (!packages || packages.length === 0) return 0
  if (!commune) return 0
  if (packages.find(p => p.withdrawalId && p.shippingCost)) return 0 // ya le cobraron hoy en retiro previo
  // console.log('costo de retiro con comuna', commune.type, 'y paquetes', packages.length)
  // Si la comuna de retiro es urbana, tengo la chance de que salga gratis con al menos 10 envíos
  if (commune.type === 'urban') {
    if (packages.length >= 10) return 0
    // console.log('comuna urbana con', packages.length, 'costo de retiro', urbanRetirementPrice)
    return urbanRetirementPrice
  }
  // Si es rural, no hay descuento
  // console.log('comuna rural, costo de retiro', ruralRetirementPrice)
  return ruralRetirementPrice
}
