<template lang="pug">
  #store-delivery-view
    b-container(fluid="lg")
      DeliveryStateCards.mb-2
      StoreMessages.mb-2
      ListStoreDelivery
</template>
<script>
import ListStoreDelivery from '../components/delivery/ListStoreDelivery'
import DeliveryStateCards from '../components/delivery/DeliveryStateCards'
import StoreMessages from '../components/delivery/StoreMessages'

export default {
  components: {
    ListStoreDelivery,
    DeliveryStateCards,
    StoreMessages
  }
}
</script>
