<template lang="pug">
  #payment-data
    .data.mt-4(v-if="noRequiresVoucher")
      .font-weight-bold.mb-1 No requiere comprobante de transferencia
      div Total: ${{total}}
    .data.mt-4(v-if="!noRequiresVoucher")
      b-row
        b-col
          .font-weight-bold.mb-1 Datos de Transferencia
        b-col
          b-button(variant="light" @click="copyToClickBoard")
            .material-icons.icon content_copy
      b-row(style="color:var(--status-assigned);font-weight:bold")
        b-col
          div Cantidad a Transferir:
        b-col
          div ${{total}}
      b-row
        b-col
          div Banco:
        b-col
          div {{ bank.trim() }}
      b-row
        b-col
          div Tipo de Cuenta:
        b-col
          div {{ typeAccount.trim() }}
      b-row
        b-col
          div N° de Cuenta:
        b-col
          div {{ Account }}
      b-row
        b-col
          div Nombre:
        b-col
          div {{ accountName.trim() }}
      b-row
        b-col
          div Rut:
        b-col
          div {{ identificationNumber.trim() }}
      b-row
        b-col
          div Email:
        b-col
          div {{ email.trim() }}
      .file.mt-4
        .font-weight-bold Comprobante
        div.mt-1.subtitle ...y luego adjúntanos el comprobante de Transferencia
        b-form-group.mt-3(:invalid-feedback="voucherInvalidFeedback")
          b-form-file(
            v-model="voucher"
            :placeholder="this.withdrawal.voucher ? this.withdrawal.voucher.name : 'Adjuntar'"
            accept=".jpg, .png, .pdf"
            style="background-color: #FEFFEE;"
            @input="setFile"
            :state="$v.$dirty ? !$v.voucher.$invalid : null"
          )
          .material-icons.icon-upload upload
        div.card-info.d-flex.align-content-center.p-2
          .material-icons(style="color: var(--status-assigned);") error
          span.ml-2 Solo archivos PDF, JPG o PNG hasta 10MB.
    .footer.d-flex.justify-content-between.my-4
      b-button(variant="outline-primary" size="sm" @click="$emit('continue', false)") Atrás
      b-button(variant="status-approved" size="sm" @click="onSubmit()") Finalizar
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import MakeToastMixin from '../../mixins/MakeToastMixin.vue'

export default {
  mixins: [MakeToastMixin],
  data () {
    return {
      noRequiresVoucher: false,
      voucher: null,
      total: null,
      bank: ' Banco Scotiabank ',
      accountName: ' SERVICIOS DE LOGÍSTICA INTEGRAL SPA ',
      typeAccount: ' Cuenta corriente ',
      Account: 984774281,
      identificationNumber: ' 77.682.511-5 ',
      email: 'contacto@12horasenvios.com',
      working: false
    }
  },
  validations: {
    voucher: {
      required,
      size: (value) => !value || value.size < 10000000,
      ext: (value) => !value || ['jpg', 'png', 'pdf'].includes(value.name.split('.')[value.name.split('.').length - 1])
    }
  },
  async mounted () {
    if (this.user.role !== 'admin' && this.store) {
      this.noRequiresVoucher = this.store.noRequiresVoucher
    }
  },
  methods: {
    ...mapMutations(['SET_WITHDRAWAL_VOUCHER']),
    ...mapActions(['createWithdrawal', 'checkWithdrawalHour']),
    onCopy (e) {
      this.makeSuccessToast('DATOS COPIADOS: ' + e.text)
    },
    onError (e) {
      this.makeDangerToast('DATOS NO COPIADOS')
      console.log(e)
    },
    copyToClickBoard () {
      if (!navigator || !navigator.clipboard) {
        return this.makeDangerToast('Navegador no cuenta con copiado al portapapeles')
      }
      const datosBanco = `Banco: ${this.bank}, Tipo de cuenta: ${this.typeAccount}, Cuenta: ${this.Account}, Nombre: ${this.accountName}, Rut: ${this.identificationNumber}, email: ${this.email}, Monto: ${this.total}`
      navigator.clipboard.writeText(datosBanco)
        .then(() => {
          this.makeSuccessToast('Datos copiados al portapapeles')
        })
        .catch((err) => {
          console.log('error al copiar', err)
          this.makeDangerToast('No se pudo copiar datos al portapapeles')
        })
    },
    async checkHour () {
      if (this.store) {
        const date = localStorage.getItem('retirementDate')
        const res = await this.checkWithdrawalHour({ date, storeId: this.store.id })
        return res.canSchedule
      }
      return true
    },
    async onSubmit () {
      if (this.working) return false
      if (this.noRequiresVoucher === true) {
        this.voucher = 'NO_REQUIERE'
      } else if (this.$v.$invalid) {
        return this.$v.$touch()
      }
      const dateOk = await this.checkHour()
      if (dateOk === false) {
        this.makeDangerToast('Lo siento, no podemos recibir envíos a esta hora para la fecha seleccionada. Intente una fecha posterior')
      } else {
        const formData = new FormData()
        const now = new Date()
        if (!this.noRequiresVoucher) {
          const extension = this.voucher.name.split('.')[1]
          formData.append('file', this.voucher, `voucher_tienda${this.store.id}_retiro${localStorage.getItem('retirementDate')}_${now.getTime()}.${extension}`)
        }
        const data = {
          storeId: this.user.storeId ? this.user.storeId : this.store.id,
          retirementDate: localStorage.getItem('retirementDate'),
          retirementPrice: this.retirementPrice.replace(/\./g, ''),
          volumeDiscount: this.volumeDiscount.replace(/\./g, ''),
          total: this.total.replace(/\./g, ''),
          packages: JSON.parse(localStorage.getItem('packages'))
        }
        formData.append('data', JSON.stringify(data))
        console.log({ formData })
        // if (this.noRequiresVoucher) {
        //   this.SET_WITHDRAWAL_VOUCHER({ voucher: this.voucher })
        // }
        this.working = true
        const resp = await this.createWithdrawal({ data: formData })
        this.working = false
        if (resp.ok) {
          this.makeSuccessToast('El retiro se ingresó correctamente')
          this.$bvModal.hide('create-withdrawal-form-modal')
          localStorage.removeItem('retirementDate')
          localStorage.removeItem('total')
          localStorage.removeItem('retirementPrice')
          localStorage.removeItem('volumeDiscount')
          localStorage.removeItem('packages')
        }
      }
    },
    setFile (file) {
      this.SET_WITHDRAWAL_VOUCHER(file)
    }
  },
  computed: {
    ...mapGetters(['withdrawal', 'user', 'store']),
    voucherInvalidFeedback () {
      if (this.$v.voucher.$dirty) {
        if (!this.$v.voucher.required) {
          return 'Este campo es requerido'
        }
        if (!this.$v.voucher.size) {
          return 'El archivo no puede pesar más de 10MB'
        }
        if (!this.$v.voucher.ext) {
          return 'Solo se permiten archivos PDF, JPG o PNG'
        }
      }
      return ''
    }
  },
  created () {
    this.total = localStorage.getItem('total')
    this.retirementPrice = localStorage.getItem('retirementPrice')
    this.volumeDiscount = localStorage.getItem('volumeDiscount')
    this.voucher = this.withdrawal.voucher
  }
}
</script>
<style lang="scss" scoped>
#payment-data {
  margin:auto;
  .data {
    margin: 0px 30%;
    @media (max-width: 920px) {
      margin: 0px 1%;
    }
  }
  .icon-upload {
    z-index: 100;
    position:absolute;
    margin: 7px -37px;
  }
}
</style>
