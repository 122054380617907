export default (packages) => {
  // Sumo costos de los envíos por region
  const regions = packages.reduce((accum, item) => {
    // Código por región
    if (item.commune && item.size) {
      const region = `${item.size === 'small' ? 'P' : 'M'}${item.commune.region ? item.commune.region.sigla : '-'}`
      const total = item.shippingCost
      const acc = accum || {}
      const paquete = 1
      let calc = {}
      calc = !acc[region] ? { ...acc, [region]: { total: total, paquetes: paquete } } : { ...acc, [region]: { total: acc[region].total + total, paquetes: acc[region].paquetes + paquete } }
      return calc
    }
  }, {})
  // Convierto el objeto a array con orden alfabético
  const data = []
  if (regions) {
    for (const [clave, valor] of Object.entries(regions)) {
      data.push({ region: clave, total: valor.total, paquetes: valor.paquetes })
    }
  }
  return data.sort((a, b) => a.region.localeCompare(b.region))
}
