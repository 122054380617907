<template lang="pug">
  #check-export-withdrawal
    b-form-checkbox(v-model="disabled" @change="changeCheck")
</template>
<script>
import { mapActions } from 'vuex'
export default {
  props: {
    withdrawal: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      disabled: this.withdrawal.disabled
    }
  },
  methods: {
    ...mapActions(['updateWithdrawal']),
    async changeCheck (value) {
      await this.updateWithdrawal({ withdrawalId: this.withdrawal.id, data: { disabled: value } })
      this.$emit('checked')
    }
  }
}
</script>
