<template lang="pug">
  .list-users
    b-row.mb-2(align-h="between")
      b-col.mb-2
        h3.d-inline Usuarios
      b-col(md="auto")
        b-button(@click="fetchData(true)" :disabled="loading" block) + Nuevo
    b-table(:items="users" :fields="fields" responsive)
      template(v-slot:cell(actions)="data")
        .material-icons-outlined.btn-icon edit
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      fields: [
        { label: 'Nombre', key: 'name' },
        { label: 'Email', key: 'email' },
        { label: 'Acciones', key: 'actions' }
      ]
    }
  },
  methods: {
    ...mapActions(['listUser', 'deleteUser']),
    fetchData () {
      this.listUser({ params: { storeId: this.$route.params.storeId } })
    }
  },
  computed: {
    ...mapGetters(['users'])
  },
  created () {
    this.fetchData()
  }
}
</script>
