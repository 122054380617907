<template lang="pug">
.tabla.sombra
  b-table.table-container(
    :fields="fields" :items="deliveries"
    responsive
  )
    template(#cell(store)="{item}")
      p {{ item.store? item.store.name : '-' }}
    template(#cell(delete)="{ index }")
      .material-icons.icon(style="color:var(--status-rejected)" @click="deleteItem(index)") highlight_off
  b-row.btn-container
    b-col(style="width: 50%;")
      b-button(block @click="deleteAllItems" :disabled="!deliveries.length" variant="outline-danger") Reiniciar
    b-col(style="width: 50%;")
      b-button(block @click="onSubmit" :disabled="!deliveries.length") Recepcionar
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.common.js'

export default {

  props: {
    deliveries: Array
  },
  data () {
    return {
      fields: [
        { label: 'Código de seguimiento', key: 'code' }
        // { label: '', key: 'delete' }
      ]
    }
  },
  methods: {
    ...mapActions(['packageScanning']),
    async onSubmit () {
      console.log('data a enviar', this.deliveries)
      const codes = this.deliveries.map(d => d.code)
      const response = await this.packageScanning({ codes: codes })
      if (response.status === 200) {
        localStorage.setItem('scan_packages', JSON.stringify([]))
        this.deliveries = []
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.$emit('error', response)
        return this.$bvToast.toast(response.data.message || 'No se pudieron recepcionar los paquetes', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 7000,
          solid: true
        })
      }
    },
    deleteItem (index) {
      this.deliveries.splice(index, 1)
      localStorage.setItem('scan_packages', JSON.stringify(this.deliveries))
    },
    async deleteAllItems () {
      const ok = await this.$root.$bvModal.msgBoxConfirm('¿Estás seguro de deseas reiniciar la lista de paquetes escaneados?', {
        title: 'Confirmar',
        okTitle: 'Confirmar',
        cancelTitle: 'Cancelar',
        centered: true
      })
      if (!ok) {
        return
      }
      this.deliveries.splice(0, this.deliveries.length)
      localStorage.setItem('scan_packages', JSON.stringify([]))
    }
  }
}

</script>

<style lang="scss">
.tabla {
  padding: 0;
}
.sombra {
  box-shadow: 0px 1px 1px #00000029;
}
.btn-container {
  background-color: #FFFFFF;
  position: sticky;
  bottom: 0%;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.table-container {
  height: calc(500px - 38vw);
  min-height: 45vh;
}
</style>
