var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"list-deliveries"}},[(_vm.user.role === 'admin')?_c('b-button',{staticClass:"float-right",attrs:{"disabled":_vm.allPackagesReceived},on:{"click":_vm.manualRecepction},model:{value:(_vm.allPackagesReceived),callback:function ($$v) {_vm.allPackagesReceived=$$v},expression:"allPackagesReceived"}},[_vm._v("Recepción Manual")]):_vm._e(),_c('b-form-checkbox',{staticStyle:{"padding-left":"2.25rem","width":"fit-content","font-weight":"bold"},attrs:{"indeterminate":_vm.indeterminate},on:{"change":_vm.toggleAll},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}},[_vm._v(_vm._s(_vm.allSelected ? 'Deseleccionar todos' : 'Seleccionar todos'))]),_c('div',{ref:"tableEl",staticStyle:{"overflow-x":"auto","width":"100%"}},[(_vm.packages)?_c('b-table',{attrs:{"items":_vm.allPackages,"fields":_vm.packagesFields},scopedSlots:_vm._u([{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{on:{"change":function($event){return _vm.handleSelect(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.id))]),(item.isCanceled)?_c('div',[_vm._v("(Cancelado)")]):_vm._e()]}},{key:"cell(receptionStatus)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{on:{"change":function($event){return _vm.handleChange(item)}},model:{value:(item.receptionStatus),callback:function ($$v) {_vm.$set(item, "receptionStatus", $$v)},expression:"item.receptionStatus"}})]}},{key:"cell(isAdditional)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.isAdditional ? 'Si' : 'No'))])]}},{key:"cell(code)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"text-wrap":"nowrap"}},[_vm._v(_vm._s(item.code))])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [(!item.isCanceled)?_c('div',[_c('b-btn',{staticClass:"mb-2",attrs:{"block":""},on:{"click":function($event){return _vm.$refs.allPackagesLabelTemplate.print([item])}}},[_vm._v("Imprimir")]),_c('b-btn',{attrs:{"variant":"outline-danger","block":""},on:{"click":function($event){return _vm.cancelPackage(item.realId)}}},[_vm._v("Cancelar")])],1):_vm._e()]}}],null,false,1314272812)}):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('p',[_vm._v("Costo de Retiro: $"+_vm._s(_vm.retirementPrice.toLocaleString('es-CL')))]),(_vm.volumeDiscount > 0)?_c('p',[_vm._v("Descuento por volumen: $"+_vm._s(_vm.volumeDiscount.toLocaleString('es-CL')))]):_vm._e()]),_c('b-btn',{attrs:{"disabled":!_vm.packagesToPrint.length},on:{"click":function($event){return _vm.$refs.allPackagesLabelTemplate.print(_vm.packagesToPrint)}}},[_vm._v("Imprimir etiquetas seleccionadas"),_c('b-badge',{staticClass:"ml-2",staticStyle:{"font-size":"0.9rem"},attrs:{"variant":"info"}},[_vm._v(_vm._s(!_vm.packagesToPrint.length ? '' : _vm.packagesToPrint.length))])],1)],1),_c('PrintAllPackagesLabel',{ref:"allPackagesLabelTemplate"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }