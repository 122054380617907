<template lang="pug">
  b-container(fluid="lg")
    .box.sombra
      h3 Mi Tienda
      StoreForm
</template>
<script>
import StoreForm from '../components/store/StoreForm.vue'

export default {
  components: {
    StoreForm
  }
}
</script>
