<template lang="pug">
#ScannerInStore
  b-button.bg-white(style="padding: 0.7rem; align-items: center; width: fit-content; display: flex; border-radius: 30px;" @click="$router.back()")
    b-icon(style="color: #181818;" icon="arrow-left")
  qrcode-stream.qr-container(@detect="onDetect")
  .qr-container-loader.text-center(v-if="loader")
    b-spinner.float
  b-button.bg-white(v-b-modal.scanned-success-deliveries-modal style="border-radius: 30px; margin-top: 0.3rem; padding: 0.71rem; align-items: center; width: fit-content; display: flex;")
    b-icon(style="padding-top: 0.12rem; color: #181818;" icon="list-ul")
    span.pl-2.code-counter(v-if="scannedSuccess && deliveries.length > 0") {{ deliveries.length }}

  b-modal#scanned-success-deliveries-modal(ref="scannedSuccessDeliveries" hide-footer title="Paquetes escaneados" centered)
    scanned-deliveries(:deliveries="deliveries" @done="onDone" @error="onError(response)")

</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import { mapActions } from 'vuex'
import ScannedDeliveries from './ScannedDeliveries.vue'

export default {

  components: {
    QrcodeStream,
    ScannedDeliveries
  },
  data () {
    return {
      code: [],
      deliveries: [],
      scannedSuccess: 0,
      loader: false,
      success_audio: null,
      error_audio: null
    }
  },
  mounted () {
    this.deliveries = JSON.parse(localStorage.getItem('scan_packages')) || []
    this.scannedSuccess = this.deliveries.length
    this.success_audio = new Audio('success_scan.mp3')
    this.error_audio = new Audio('error_scan.wav')
  },
  methods: {
    ...mapActions(['packageScanning']),
    async onDetect (promise) {
      try {
        this.loader = true
        const {
          content
        } = await promise
        console.log(content.split('/recepcion-de-paquetes/')[1], 'contenido')
        const deliveryId = content.split('/recepcion-de-paquetes/')[1]
        const duplicated = this.deliveries.filter(d => d.code === deliveryId)
        if (deliveryId) {
          if (duplicated.length) {
            await this.error_audio.play()
            this.loader = false
            return this.$bvToast.toast(`El paquete ${deliveryId} ya fue escaneado`, {
              title: 'Error',
              variant: 'danger',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 7000,
              solid: true
            })
          }
          if (deliveryId) {
            await this.success_audio.play()
            this.loader = false
            this.code.push(deliveryId)
            this.deliveries.push({ code: deliveryId })
            localStorage.setItem('scan_packages', JSON.stringify(this.deliveries))
            this.scannedSuccess += 1

            return this.$bvToast.toast('Escaneado con éxito', {
              title: `Paquete ${deliveryId}`,
              variant: 'success',
              toaster: 'b-toaster-top-center',
              autoHideDelay: 7000,
              solid: true
            })
          } else if (!deliveryId) {
            this.loader = false
            console.log('Error al intentar cambiar estado')
          }
        } else {
          this.loader = false
          return this.$bvToast.toast(`Código de paquete inválido: ${deliveryId}`, {
            title: 'Error',
            variant: 'danger',
            toaster: 'b-toaster-top-center',
            autoHideDelay: 7000,
            solid: true
          })
        }
      } catch (error) {
        this.loader = false
        await this.error_audio.play()
        console.log('error al intentar leer Código Qr')
        return this.$bvToast.toast(`Ocurrió un error: ${error}`, {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 7000,
          solid: true
        })
      }
    },
    onDone () {
      this.loader = false
      console.log('evento done emitido')
      this.deliveries = []
      this.$refs.scannedSuccessDeliveries.hide()
      localStorage.setItem('scan_packages', JSON.stringify([]))
      return this.$bvToast.toast('Los paquetes fueron recepcionados con éxito!', {
        title: 'Éxito',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 7000,
        solid: true
      })
    },
    onError (response) {
      console.log('evento error emitido')
    }
  }
}

</script>

<style lang="scss" scoped>
#ScannerInStore {
  background-color: #191919;
  height: 100%;
  display: flex;
  flex-direction: column;
  .qr-container {
    padding: 0.4rem 0rem;

    .qrcode-stream-camera {
      border-radius: 15px;
    }
  }
  .qr-container-loader {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    height: calc(100% - 170px);
    position: absolute;
    width: 93%;
  }
}
</style>
<style lang="scss">
#scanned-success-deliveries-modal {
  .modal-dialog-centered {
    align-items: end !important;
  }
  .modal-content {
    border-radius: 16px 16px 0 0 !important;
    transition-timing-function: ease-out !important;
    transition-duration: 300ms !important;

    .modal-body {
      padding: 0.7rem 0.4rem;
      padding-bottom: 0;
    }
    .table th, .table td {
      padding: 0.4rem;
      vertical-align: middle;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
.code-counter {
  color: #13475c;
}
</style>
