<template lang="pug">
.package-label-template-container
  #package-label-template
    .images-content
      .store-logo
        img(v-if="!context.logoUrl" src='@/assets/black_airplane_label.png' alt="store-logo")
        img(v-if="context.logoUrl" :src="context.logoUrl" alt="store-logo")
      .qr-code
        img.qr-img(v-if="context.qrCode" :src="context.qrCode" alt="código-qr")
        img.qr-img(v-if="!context.qrCode" src='@/assets/qr-etiquetas.png' alt="código-qr")
        .delivery-code
          div {{ context.code }}
    .info-content
      .info
        div {{ context.storeName }}
        div {{ context.customerName }}
        div {{ context.customerFullAddress }}
        div {{ context.commune }}
        div {{ context.customerPhone }}
        div {{ context.observations }}
        b {{ toUpper(context.tags) }}
      .footer
        div.customFieldOne {{context.customizableFieldOne || 'www.12horasenvios.cl' }}
        div.customFiedTwo {{ context.customizableFieldTwo || '+569 5159 3606' }}
</template>

<script>

export default {
  props: {
    context: {
      type: Object,
      default: () => ({
        storeName: '-',
        customerName: '-',
        customerPhone: '-',
        customerFullAddress: '-',
        observations: '-',
        code: '-',
        qrCode: '-',
        commune: '-',
        tags: ''
      })
    }
  },
  methods: {
    toUpper (text) {
      return text.toUpperCase()
    }
  }
}
</script>

<style scoped>
.package-label-template-container {
  @media (max-width: 600px) {
    width: 60mm;
    height: 60mm;
  }
}
#package-label-template {
  text-align: left;
  height: 100mm;
  width: 100mm;
  padding: 4mm 4mm;
  margin: 0;
  font-size: 3.2mm;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  align-items: stretch;
  @media (max-width: 600px) {
    transform: scale(0.6);
    transform-origin: top left;
  }
}
.info-content {
  display: flex;
  gap: 2mm;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  align-items: stretch;
}

.images-content {
  margin: 2mm;
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}

.qr-code {
  width: 46mm;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0mm;
}
.qr-img {
  width: 40mm !important;
  height: 40mm !important;
  margin-bottom: 2mm;
}
.delivery-code {
  display: flex;
  font-size: 4.5mm;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  padding-top: 0cm;
}
.info {
  border: solid 1px black;
  border-radius: 20px 20px 0 0;
  padding: 0.2cm 0.2cm;
  margin: 0;
  height: 30mm;
}
.footer {
  display: flex;
  justify-content: space-around;
  border: 1px solid black;
  border-radius: 0 0 20px 20px;
  padding: 0.2cm 0.2cm;
  paddin-bottom: 0;
  font-size: 4.2mm;
  font-weight: 600;
  text-wrap: nowrap;
}

img {
  width: 46mm;
  height: 46mm;
}

body {
  margin: 0;
}
@media print {
  @page {
    size: 100mm 100mm;
    margin: 0;
    padding: 0;
    align-items: center;
  }
}
</style>
