<template lang="pug">
  #Selected-store
    .box
      div.mb-3(style="width:300px")
        h5 Selecciona Tienda
        .box-form
          b-form-group.input-title(
            label-for="withdrawal-store-input"
            invalid-feedback="Este campo es requerido"
          )
            b-form-select#withdrawal-store-input.input(
              v-model="storeSelectedId"
              :options="listStore"
              placeholder="Ingresa una Tienda"
              @change= "selectStore()"
            )
    .footer.d-flex.justify-content-between.my-4
      b-button(variant="outline-primary" size="sm" @click="$bvModal.hide('create-withdrawal-form-modal')") Cancelar
      b-button( v-b-modal.create-withdrawal-form-modal variant="status-approved") Continuar
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import WithdrawalForm from './WithdrawalForm'
export default {
  data () {
    return {
      storeSelectedId: '',
      stores: [],
      backendFilters: [],
      requires: false,
      showRetiro: true,
      showFacturacion: true,
      retirementDate: null,
      scheduleError: false,
      calendarLabel: false
    }
  },
  components: {
    WithdrawalForm
  },
  validations: {
    retirementDate: { required }
  },
  mounted () {
    this.getStores()
  },
  methods: {
    ...mapActions(['checkWithdrawalHour', 'listAllStoreName', 'listAllStoreIdName', 'getStore']),
    async selectStore () {
      const idstore = this.storeSelectedId
      await this.getStore({ storeId: idstore })
    },
    async getStores () {
      const stores = await this.listAllStoreIdName()
      this.stores = stores
    }
  },
  computed: {
    ...mapGetters(['withdrawal', 'store', 'user']),
    listStore () {
      return this.stores.map(i => {
        return {
          text: i.name,
          value: i.id
        }
      }).concat({
        text: 'Selecciona una Tienda',
        value: null
      })
    }
  },
  created () {
    this.retirementDate = localStorage.getItem('retirementDate')
  }
}
</script>
<style lang="scss" scoped>
#store-data {
  .box {
    margin: auto 120px;
    @media (max-width: 992px) {
      margin: -20px;
    }
  }
  .fila {
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .edit-btn {
    margin-bottom: -38px;
    margin-top: 20px;
    z-index: 100;
  }
}
</style>
