<template lang="pug">
  div
    .tabla.sombra
      b-row.mb-2(no-gutters style="gap: 10px")
        b-col
          h3 Envíos
        b-col(md="auto")
          b-button(@click="onDownloadExcelDelivery()" block) Exportar Excel
        b-col(md="auto" v-if="this.user.role === 'admin'")
          b-button(@click="$bvModal.show('assign-driver-modal')" block variant="status-approved" :disabled="checkBoxDriver.length === 0") Asignar órdenes
        b-col(v-if="user.role==='admin'" md="auto")
          b-button(v-b-modal.upload-deliveries-modal @click="" block variant="status-approved" :disabled="false") Subir carga masiva
      b-form.mb-2(@submit.stop.prevent="fetchData()")
        b-form-row
          b-col.mb-2(cols="12" md="2")
            b-form-input(v-model="backendFilters.search" placeholder="Buscar...")
          b-col.mb-2(cols="12" md="2")
            b-form-select(v-model="backendFilters.status")
              b-form-select-option(:value="null") Selecciona un Estado
              b-form-select-option(:value="'approved'") Aprobados
              b-form-select-option(:value="'assigned'") Asignados
              b-form-select-option(:value="'in-transit'") En Tránsito
              b-form-select-option(:value="'partial'") Parciales
              b-form-select-option(:value="'rejected'") Rechazados
          b-col.mb-2(cols="12" md="3" lg="2" style="max-width: fit-content;")
            div
              b-button.toggle-btn(
                :style="styleDateBtn"
                style="border-radius: 5px 0px 0px 5px;"
                @click="toggleBtn('date')"
              ) Por fecha
              b-button.toggle-btn(
                :style="styleRangeBtn"
                style="border-radius: 0px 5px 5px 0px;"
                @click="toggleBtn('range')"
              ) Por rango
          b-col.mb-2(v-if="backendFilters.isByRange" cols="12" md="3" lg="2" style="max-width: fit-content")
            b-form-datepicker(
              label-no-date-selected="Desde..."
              v-model="backendFilters.startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :min="backendFilters.minStartDate"
              :max="backendFilters.date"
              @input="setDateRange"
              reset-button
              style="width: fit-content"
            )
          b-col.mb-2(v-if="backendFilters.isByRange" cols="12" md="3" lg="2" style="max-width: fit-content")
            b-form-datepicker(
              label-no-date-selected="Hasta..."
              v-model="backendFilters.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              :min="backendFilters.startDate"
              :max="backendFilters.maxEndDate || backendFilters.date"
              reset-button
              style="width: fit-content"
            )
          b-col.mb-2(v-if="!backendFilters.isByRange" cols="12" md="3" lg="2")
            b-form-datepicker(
              label-no-date-selected="sin fecha"
              v-model="backendFilters.date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              reset-button
            )
          b-col.mb-2(cols="12" md="3" lg="2")
            v-select(
                v-model="backendFilters.driver"
                style="height: 40px;"
                :options="driversFilterOptions"
                label="text"
                placeholder="Buscar conductores..."
              )
              span(slot="no-options") No hay conductores disponibles
          b-col(cols="12" md="auto")
            b-btn(type="submit" variant="primary" block) Filtrar
      div(ref="tableEl" style="overflow-x: auto; width: 100%;")
        b-table(:fields="fields" :items="deliveries")
          template(#cell(assign_driver)="{ item }")
            b-form-checkbox(v-model="item.driverAssigned" @change="asignDriverSelected(item)")
          template(#cell(driverCode)="{item}")
            div {{ item.driver ? `${item.driver.codeDriver}` : '' }}
          template(#cell(driver)="{value}")
            div {{ value ? `${value.name} ${value.lastName}` : '' }}
          template(#cell(date)="{value}")
            div(style="min-width: 80px") {{ value | moment('DD/MM/YYYY') }}
          template(#cell(actions)="{item}")
            .botones-accion
              .material-icons-outlined.btn-icon.mr-2.pt-1(v-if="user.role==='admin'" @click="openEditModal(item)") edit
              .material-icons-outlined.btn-icon.mr-2.pt-1.boton-eliminar(v-if="user.role==='admin'" @click="onDeleteDelivery(item)") delete_outline
              a.text-secondary.material-icons-outlined.btn-icon.mr-2.pt-1(
                v-if="item.pdfUrl && ['partial', 'rejected', 'approved'].includes(item.status)"
                :href="item.pdfUrl"
                target="_blank"
                style="cursor: pointer; text-decoration: none; color: ;"
              ) file_open
              a.text-secondary.material-icons-outlined.btn-icon.mr-2.pt-1(
                v-if="['partial', 'rejected', 'approved'].includes(item.status)" target="_blank" variant="ligth" size="sm"
                :href="`/prueba-entrega/${item.id}`"
                style="cursor: pointer; text-decoration: none; opacity: 0.75;"
              ) file_open
          template(#cell(status)="{value}")
            StatusBadge(:status="value")
        .d-flex.justify-content-center
          b-pagination.m-auto(
            @change="backendFilters.page = $event, fetchData()"
            :total-rows="countRows"
            :per-page="backendFilters.size"
            aria-controls="my-table"
          )
      b-modal#assign-driver-modal(hide-footer='')
        template(#modal-title='') Conductores
        .d-block
        b-form-group(
          label-for="select-driver"
          invalid-feedback="Este campo es requerido"
        )
          .mb-2 Seleccione Conductor
          v-select(
              v-model="selectedDriver"
              :options="driversAvailableOptions"
              label="text"
              placeholder="Buscar conductores..."
            )
            span(slot="no-options") No hay conductores disponibles
        .d-flex.justify-content-end.mt-2
          b-button(@click="assignDriver" :disabled="!selectedDriver" variant="primary") Asignar

    //- b-modal#create-delivery-form-modal(ref="createDeliveryFormModal" hide-footer title="Creando Envío")
    //-   delivery-form(@done="onDoneDeliveryModal")
    b-modal(ref="updateDeliveryFormModal" hide-footer :title="`Editando Viaje ${selectedDelivery ? selectedDelivery.drivInCode: ''}`")
      delivery-form(v-if="selectedDelivery" @done="onDoneDeliveryModal" :delivery="selectedDelivery" :editMode="true")
    b-modal#upload-deliveries-modal(title="Importar Envíos" ok-only @hide="isExcelErrors = true, isFinish = false, fetchData()")
      upload-deliveries-from-excel(ref="uploadDeliveryFromExcelForm" @errors="checkExcelErrors" @showSaveBtn="toggleSaveBtn" @done="onDoneDeliveryModal")
      template(#modal-footer="{close}")
        b-button(v-if="!isFinish" :disabled="isExcelErrors" @click="$refs.uploadDeliveryFromExcelForm.onSubmit()") Guardar
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import DeliveryStateCards from './DeliveryStateCards'
import StatusBadge from '../common/StatusBadge'
import DeliveryForm from '../delivery/DeliveryForm'
import MakeToastMixin from '../mixins/MakeToastMixin'
import UploadDeliveriesFromExcel from './UploadDeliveriesFromExcel'
import { io } from 'socket.io-client'
import settings from '../../../settings.js'
import html2pdf from 'html2pdf.js'
import Handlebars from 'handlebars'

export default {
  mixins: [MakeToastMixin],
  props: {
    deliveryList: Array
  },
  components: {
    DeliveryStateCards,
    StatusBadge,
    vSelect,
    DeliveryForm,
    UploadDeliveriesFromExcel
  },
  data () {
    return {
      activeBtn: 'color: #fff; background-color: #3276b1; border-color: #285e8e; transform: translateY(0.8px);',
      inactiveBtn: 'color: #333; background-color: #fff; border-color: #ccc;',
      styleDateBtn: 'color: #fff; background-color: #3276b1; border-color: #285e8e; transform: translateY(0.8px);',
      styleRangeBtn: 'color: #333; background-color: #fff; border-color: #ccc;',
      backendFilters: {
        search: '',
        driver: null,
        page: 1,
        size: 30,
        date: new Date().toISOString().slice(0, 10),
        startDate: null,
        endDate: null,
        status: null,
        isByRange: false,
        minStartDate: null,
        maxStartDate: null,
        maxEndDate: null
      },
      countRows: 0,
      driversAvailableOptions: [],
      driversFilterOptions: [],

      selectedDriver: null,
      checkBoxDriver: [],
      driverAssigned: null,
      isExcelErrors: true,
      drivers: [],
      selectedDelivery: null,
      showSaveBtn: false,
      isFinish: false,
      storesToEmit: [],
      driversToEmit: [],
      websocketUrl: settings.baseApi.split('api')[0]
    }
  },
  methods: {
    ...mapActions(['listDelivery', 'downloadExcelDelivery', 'getDrivers', 'assignDriverDelivery', 'deleteDelivery']),
    ...mapActions(['listDriver', 'generatePDF']),
    onDownloadExcelDelivery () {
      const params = { ...this.backendFilters }
      if (params.driver) params.driver = params.driver.value
      this.downloadExcelDelivery({ params })
    },
    async assignDriver () {
      const response = await this.assignDriverDelivery({ data: { idDelivery: this.checkBoxDriver, driverId: this.selectedDriver } })
      if (response.ok) {
        this.driversToEmit.push(this.selectedDriver.value)
        const driversToEmit = [...new Set(this.driversToEmit)]
        const storesToEmit = [...new Set(this.storesToEmit)]
        this.selectedDriver = null
        this.$bvModal.hide('assign-driver-modal')
        this.makeSuccessToast(response.data.message)
        this.checkBoxDriver = []
        if (this.socket) {
          this.socket.emit('delivery:updated', { update: { drivers: driversToEmit, stores: storesToEmit } })
          this.storesToEmit = []
          this.driversToEmit = []
        }
        this.fetchData()
      }
    },
    async downloadPDF (item) {
      const id = item.id
      this.$bvToast.toast('Generando PDF', {
        variant: 'success',
        solid: true,
        title: 'PDF'
      })
      const response = await this.generatePDF({ id })
      if (response.ok) {
        const responseHtml = await fetch('/templatePDF.html')
        const template = await responseHtml.text()
        const compiledTemplate = Handlebars.compile(template)
        const pdfContainer = document.createElement('div')
        pdfContainer.innerHTML = compiledTemplate(response.data)
        const options = {
          margin: [0.51069, 0.98, 0.51069, 0.89],
          image: { type: 'jpeg', quality: 1.0 },
          jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
          html2canvas: {
            letterRendering: true,
            useCORS: true,
            scale: 2
          }
        }

        const pdf = await html2pdf().from(pdfContainer).set(options).output('blob')
        const pdfUrl = URL.createObjectURL(pdf)
        window.open(pdfUrl)

        // Crear un enlace temporal para descargar el PDF
        const link = document.createElement('a')
        link.href = pdfUrl
        link.download = `${item.drivInCode}/${item.address}, ${item.commune}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(pdfUrl) // Liberar memoria
      } else {
        this.$bvToast.toast('No se pudo generar el PDF', {
          variant: 'danger',
          solid: true,
          title: 'PDF'
        })
      }
    },
    async fetchDrivers () {
      const response = await this.listDriver()
      this.drivers = response.data
    },
    asignDriverSelected (item) {
      const index = this.checkBoxDriver.indexOf(item.id)
      if (item.driverAssigned && index === -1) {
        this.checkBoxDriver.push(item.id)
        if (item.customerName) this.storesToEmit.push(item.customerName)
        if (item.driverId) this.driversToEmit.push(item.driverId)
      } else if (!item.driverAssigned && index !== -1) {
        this.checkBoxDriver.splice(index, 1)
        if (item.customerName) {
          const storeToDelete = this.storesToEmit.indexOf(item.customerName)
          if (storeToDelete !== -1) this.storesToEmit.splice(storeToDelete, 1)
        }
        if (item.driverId) {
          const driverToDelete = this.driversToEmit.indexOf(item.driverId)
          if (driverToDelete !== -1) this.driversToEmit.splice(driverToDelete, 1)
        }
      }
    },
    async fetchData (sync = false) {
      const params = { ...this.backendFilters, sync }
      params.driver = this.backendFilters.driver ? this.backendFilters.driver.value : null

      if (!params.date) {
        delete params.date
      }
      const response = await this.listDelivery({ params })
      if (response) {
        this.countRows = response.data.count
      }
    },
    toggleRange () {
      this.backendFilters.isByRange = !this.backendFilters.isByRange

      if (!this.backendFilters.isByRange) {
        this.backendFilters.startDate = null
        this.backendFilters.endDate = null
      } else {
        this.backendFilters.date = new Date().toISOString().slice(0, 10)
      }
    },
    setDateRange (value) {
      this.backendFilters.endDate = null
      if (!value) return

      const date = new Date(value)
      const today = new Date()
      const maxDate = new Date(date.setMonth(date.getMonth() + 1))

      this.backendFilters.minEndDate = value
      if (maxDate.getTime() > today.getTime()) {
        this.backendFilters.maxEndDate = this.backendFilters.date
      } else {
        this.backendFilters.maxEndDate = maxDate.toISOString().slice(0, 10)
      }

      // console.log(this.backendFilters, 'filtros del back')
    },
    toggleBtn (btn) {
      if (btn === 'date') {
        this.styleDateBtn = this.activeBtn
        this.styleRangeBtn = this.inactiveBtn
        if (this.backendFilters.isByRange) {
          this.toggleRange()
        }
      } else {
        this.styleRangeBtn = this.activeBtn
        this.styleDateBtn = this.inactiveBtn
        if (!this.backendFilters.isByRange) {
          this.toggleRange()
        }
      }
    },
    openEditModal (delivery) {
      this.selectedDelivery = delivery
      this.$refs.updateDeliveryFormModal.show()
    },
    async onDeleteDelivery (delivery) {
      const ok = await this.$bvModal.msgBoxConfirm(`¿Está seguro que desea eleminar el viaje ${delivery.drivInCode} de la tienda${delivery.customerName ? ' ' + delivery.customerName : ''}?`)
      if (!ok) return

      const response = await this.deleteDelivery({ id: delivery.id })
      if (response.status === 200) {
        this.makeSuccessToast('El viaje se eliminó correctamente')
        if (this.socket) {
          const updateData = { update: { drivers: [], stores: [] } }
          if (delivery.driverId) updateData.update.drivers.push(delivery.driverId)
          if (delivery.customerName) updateData.update.stores.push(delivery.customerName)
          this.socket.emit('delivery:updated', updateData)
        }
        this.$emit('done')
      } else if (response.status >= 400 && response.status < 500) {
        this.makeDangerToast(response.data.message || 'Error al eliminar el viaje')
      }
      await this.fetchData()
    },
    onDoneDeliveryModal (data) {
      if (!this.isFinish) this.isFinish = true
      this.listDelivery()
      // this.$refs.createDeliveryFormModal.hide()
      this.$refs.updateDeliveryFormModal.hide()
      if (this.socket) {
        this.socket.emit('delivery:updated', { update: data })
      }
      this.$emit('done')
      this.fetchData()
    },
    toggleSaveBtn (value) {
      this.showSaveBtn = value
    },
    checkExcelErrors (value) {
      this.isExcelErrors = value
      console.log('Errores encontrados: ', this.isExcelErrors)
    },
    async initWebsockets () {
      console.log('conectando a websocket')
      this.socket = io(this.websocketUrl)
      this.socket.on('delivery:created', (data) => {
        console.log('Envío creado')
        this.fetchData()
      })
      this.socket.on('delivery:updated', (data) => {
        console.log('Envío actualizado', data || '')
        if (this.user.role === 'admin') this.fetchData()
        if (this.user.role === 'store') {
          if (data.update.stores?.includes(this.user.store.name) || data.update.stores?.includes(this.user.store.storeName)) {
            // this.fetchData()
          }
        }
      })
    },
    handleKeydown (e) {
      if (e.key === 'ArrowLeft') {
        this.$refs.tableEl.scrollBy(-100, 0)
      } else if (e.key === 'ArrowRight') {
        this.$refs.tableEl.scrollBy(100, 0)
      }
    }
  },
  computed: {
    ...mapGetters(['deliveries', 'store', 'user']),
    fields () {
      const fields = [
        { label: 'Fecha', key: 'date' },
        { label: 'Código', key: 'drivInCode' },
        { label: 'Destinatario', key: 'finalCustomer' },
        { label: 'Teléfono', key: 'contactPhone' },
        { label: 'Dirección', key: 'address' },
        { label: 'Comuna', key: 'commune' },
        { label: 'Observaciones', key: 'observations' },
        { label: 'Tags', key: 'tagsName' },
        { label: 'Estado', key: 'status' },
        { label: 'Comprobante', key: 'actions' }
      ]
      if (this.user.role === 'admin') {
        fields.splice(0, 0, { label: 'Asignar', key: 'assign_driver' })
        fields.splice(3, 0, { label: 'Proveedor', key: 'supplierName' })
        fields.splice(4, 0, { label: 'Tienda', key: 'customerName' })
        fields.splice(10, 0, { label: 'Cod. Conductor', key: 'driverCode' })
        fields.splice(11, 0, { label: 'Conductor', key: 'driver' })
      }
      return fields
    }
  },
  async created () {
    this.initWebsockets()
    await this.fetchData()
    this.fetchDrivers()
    if (this.user.role === 'admin') {
      const drivers = await this.getDrivers()
      this.driversFilterOptions.push({ value: 'NULL', text: 'Sin Conductor' })
      // Ordenar conductores por nombre
      drivers.sort((a, b) => a.name.localeCompare(b.name))
      drivers.map(driver => {
        this.driversAvailableOptions.push({ value: driver.id, text: `${driver.name} ${driver.lastName} (${driver.codeDriver})` })
        this.driversFilterOptions.push({ value: driver.id, text: `${driver.name} ${driver.lastName} (${driver.codeDriver})` })
      })
    }
  },
  destroyed () {
    if (!this.socket) return
    this.socket.disconnect()
    console.log('conexión a websocket terminada')
  },
  mounted () {
    window.addEventListener('keydown', this.handleKeydown)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  watch: {
    'backendFilters.status': {
      handler (value) {
        this.backendFilters.page = 1
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #869099;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #ced4da;
}
.botones-accion {
  display: inline-flex;
}
</style>

<style lang="scss">

.vs__dropdown-toggle {
  height: 38px;
}
</style>
