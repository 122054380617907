<template lang="pug">
  .login-view
    .bg-top
        img.login-view-paper-plane(src="../assets/logos/avion_naranjo.png")
    b-row.h-100.m-2(align-h="center" align-v="center")
      b-col(sm="8" md="6" xl="4" xxl="3")
        LoginCardTemplate
          LoginForm(v-if="showLogin" style="margin-top: 60px;")
          PreLogin(v-else @showLogin="showLogin=true" style="margin-top: 40px;")
    Footer
</template>

<script>
import LoginForm from '../components/auth/LoginForm'
import PreLogin from '../components/auth/PreLogin'
import Footer from '../components/auth/Footer'
import LoginCardTemplate from '../components/auth/LoginCardTemplate'

export default {
  components: {
    LoginForm,
    PreLogin,
    Footer,
    LoginCardTemplate
  },
  data () {
    return {
      showLogin: false
    }
  }
}
</script>

<style lang="scss">
.login-view {
  position: relative;
  .bg-top {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    height: 36%;
    background: var(--secondary);
    display: flex;
    justify-content: center;
  }

  &-paper-plane {
    position: absolute;
    top: calc(60% - 130px);
    margin: auto;
    width: auto;
    max-height: 80px;
  }

  height:100%;
  &-container {
    height: 100%;
  }
}
.login {
  margin: auto;
}
</style>
