<template lang="pug">
  #DeliveryStateCards
    b-row(cols="1" cols-md="4")
      b-col.mb-2
        state-count-card(
          stateName="En Transito"
          color="var(--status-in-transit)"
          icon="local_shipping"
          :stateCount="countStatus['in-transit']"
        )
      b-col.mb-2
        state-count-card(
          stateName="Aprobados"
          color="var(--status-approved)"
          icon="inventory"
          :stateCount="countStatus['approved']"
        )
      b-col.mb-2
        state-count-card(
          stateName="Parciales"
          color="var(--status-partial)"
          icon="block"
          :stateCount="countStatus['partial']"
        )
      b-col.mb-2
        state-count-card(
          stateName="Rechazados"
          color="var(--status-rejected)"
          icon="thumb_down"
          :stateCount="countStatus['rejected']"
        )
</template>

<script>
import StateCountCard from '../UI/StateCountCard'
import { mapGetters } from 'vuex'

export default {
  components: {
    StateCountCard
  },
  computed: {
    ...mapGetters(['countStatus'])
  }
}
</script>
