<template lang="pug">
  b-container(fluid="lg")
    .tabla.sombra
      list-messages
</template>

<script>
import ListMessages from '../components/message/ListMessages.vue'

export default {
  components: {
    ListMessages
  }
}
</script>
