import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const WithdrawStore = {
  state: {
    withdrawals: [],
    withdrawal: {
      voucher: null
    }
  },
  getters,
  mutations,
  actions
}

export default WithdrawStore
