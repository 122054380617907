export default `
#package-label-template {
  height: 100mm;
  width: 100mm;
  padding: 4mm 4mm;
  margin: 0mm;
  font-size: 3.8mm;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  align-items: stretch;
}
.info-content {
  display: flex;
  gap: 2mm;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  align-items: stretch;
}

.images-content {
  margin: 2mm;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}

.qr-code {
  width: 45mm;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0mm;
}
.qr-img {
  margin: 0mm;
  width: 42mm;
  margin-bottom: 2mm;
}
.delivery-code {
  display: flex;
  font-size: 4.5mm;
  flex-direction: column;
  text-align: center;
  font-weight: 600;
  padding-top: 0cm;
}
.info {
  border: solid 1px black;
  border-radius: 20px 20px 0 0;
  padding: 0.2cm 0.2cm;
  margin: 0;
  height: 30mm;
}
.footer {
  display: flex;
  justify-content: space-around;
  border: 1px solid black;
  border-radius: 0 0 20px 20px;
  padding: 0.2cm 0.2cm;
  paddin-bottom: 0;
  font-size: 4.2mm;
  font-weight: 600;
}

.store-logo {
  img {
    margin: 0mm;
    width: 47mm;
    height: 47mm;
  }
}
body {
  margin: 0;
}
@media print {
  @page {
    size: 100mm 100mm;
    margin: 0;
    padding: 0;
    align-items: center;
  }
}`
